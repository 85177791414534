import GetInsightsHeader from '../../../components/GetInsights/Header';
import PDFViewer from '../../../components/GetInsights/PDFViewer';
import Flex from 'styled-flex-component';
import InsightsSidebar from '../../../components/GetInsights/Sidebar';
import Container from '../../../components/UI/Common/Container';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { getDocumentInsights } from '../../../api/files.api';
import { getUserSessionData } from '../../../utils/userSession';
import { isEmpty } from 'lodash';
import { BoxDetails, IDocument } from '../../../@types/Document';
import { adminGetShadowDownloadLinkWithBoxDetails } from '../../../api/box/admin/file';
import GetInsightsProvider, {
    GetInsightsContext,
    GetInsightsContextType,
} from './context/getInsightsContext';
import PDFViewerPageIndicator from '../../../components/GetInsights/PDFViewerPageIndicator';
import GetInsightsLayoutStyled from '../../../components/GetInsights/Layout/Layout.styled';
import WSPageLoadingPlaceholder from './WSPageLoadingPlaceholder';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { DragIndicator } from '@mui/icons-material';

const GetInsightsPage = () => {
    const routeParams = useParams();
    const navigate = useNavigate();
    const user = getUserSessionData();
    const documentId = routeParams['documentId'];
    const [isLoadingDocumentData, setIsLoadingDocumentData] = useState<boolean>(true);
    const [fileUrl, setFileUrl] = useState<string>('');
    const [documentData, setDocumentData] = useState<IDocument>();
    const [sidebarSize, setSidebarSize] = useState<number>();
    const [newDocType, setNewDocType] = useState<string>('');

    if (isEmpty(user)) {
        navigate('/');
    }

    if (!documentId) {
        navigate(`/client/documents`);
    }

    async function downloadFile(boxDetails: BoxDetails) {
        try {
            // Get the "shadow" version of the file, i.e. the one with OCR, used for rendering.
            const fileUrl = await adminGetShadowDownloadLinkWithBoxDetails(boxDetails);

            setFileUrl(fileUrl);
        } catch (e) {
            console.error('downloadFile error', e);
        }
    }

    async function getDocument() {
        try {
            const response = await getDocumentInsights(documentId as string);
            setDocumentData(response);
            setIsLoadingDocumentData(false);
            downloadFile(response.box_details as BoxDetails);
        } catch (e) {
            console.error('getDocument e', e);
        }
    }

    useEffect(() => {
        getDocument();
    }, []);

    window.addEventListener('keydown', function (event) {
        if (
            (event.ctrlKey || event.metaKey) &&
            String.fromCharCode(event.which).toLowerCase() == 'f'
        ) {
            event.preventDefault();
        }
    });

    if (documentData?.type === 'unknown') {
        return <WSPageLoadingPlaceholder sha1={documentData.id} onDocumentReady={getDocument} />;
    }

    return (
        <>
            <GetInsightsProvider
                documentData={documentData}
                isLoadingDocumentData={isLoadingDocumentData}
                fileUrl={fileUrl}
            >
                <GetInsightsLayoutStyled>
                    <GetInsightsHeader setNewDocType={setNewDocType} />
                    <Container topInnerSpacing={4}>
                        <Flex justifyBetween full>
                            <PanelGroup autoSaveId="sidebar" direction="horizontal">
                                <Panel
                                    defaultSize={20}
                                    minSize={20}
                                    order={1}
                                    onResize={(size) => {
                                        setSidebarSize(size);
                                    }}
                                >
                                    <InsightsSidebar
                                        getDocument={getDocument}
                                        sidebarSize={sidebarSize as number}
                                        newDocType={newDocType}
                                    />
                                </Panel>
                                <PanelResizeHandle style={{ width: '5px' }} />
                                <Panel order={2}>
                                    <Container
                                        topInnerSpacing={1.0625}
                                        bottomInnerSpacing={1.0625}
                                        leftInnerSpacing={1.6875}
                                        rightInnerSpacing={1.6875}
                                        height="calc(100vh - 98px)"
                                        width="calc(100vh - ${sidebarSize})"
                                    >
                                        <PDFViewer />
                                        <PDFViewerPageIndicator />
                                    </Container>
                                </Panel>
                            </PanelGroup>
                            ;
                        </Flex>
                    </Container>
                </GetInsightsLayoutStyled>
            </GetInsightsProvider>
        </>
    );
};

export default GetInsightsPage;
