import { Info } from '@mui/icons-material';
import { CircularProgress, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { IComparison } from '../../../@types/Comparison';

interface Props {
    comparison: IComparison;
    onReUploadSuccess: () => void;
}

export default function ErrorInfoDropdown({ comparison, onReUploadSuccess }: Props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const reRun = async () => {
        try {
            setIsLoading(true);
            // await reProcessDocument(document.box_details?.file_id as string);//TODO-VAN
            toast.success('Successfully re-run comparison.');
        } catch (e) {
            toast.error('Something went wrong. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading ? (
                <CircularProgress size={20} color="inherit" />
            ) : (
                <Info
                    onClick={handleClick}
                    aria-owns={anchorEl ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                    style={{ cursor: 'pointer' }}
                />
            )}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ onMouseLeave: handleClose }}
            >
                <MenuItem onClick={reRun}>
                    <Typography color="error">re-run</Typography>
                </MenuItem>
            </Menu>
        </>
    );
}
