import { BoxDetails } from '../../../@types/Document';
import backendAPIClient from '../../apiClient';
import { getDocumentDetail } from '../../files.api';

export const adminGetDownloadLink = async (documentId: string) => {
    const document = await getDocumentDetail(documentId);

    return backendAPIClient
        .get(`/admin/files/${document.box_details?.file_id}/download-link`, {
            headers: {
                'x-box-user': document.box_details?.user_id,
                'x-secret': process.env.REACT_APP_BOX_SECRET,
            },
        })
        .then((res) => res.data);
};

export const adminGetDownloadLinkWithBoxDetails = async (boxDetails: BoxDetails) => {
    return backendAPIClient
        .get(`/admin/files/${boxDetails?.file_id}/download-link`, {
            headers: {
                'x-box-user': boxDetails?.user_id,
                'x-secret': process.env.REACT_APP_BOX_SECRET,
            },
        })
        .then((res) => res.data);
};

export const adminGetShadowDownloadLinkWithBoxDetails = async (boxDetails: BoxDetails) => {
    return backendAPIClient
        .get(`/admin/files/${boxDetails.file_id}/shadow/download-link`, {
            headers: {
                'x-box-user': boxDetails.user_id,
                'x-secret': process.env.REACT_APP_BOX_SECRET,
            },
        })
        .then((res) => res.data);
};
