import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import InsightsPage from './Insights';
import DocumentsPage from './Documents';
import SavedQueriesPage from './SavedQueries';
import { getUserSessionData } from '../../utils/userSession';
import { useJwt } from 'react-jwt';
import { isEmpty } from 'lodash';
import AdvancedSearchPage from './AdvancedSearch';
import { GetOrgMetadata } from '../../api/frontegg/tenants';
import { getUserConfig, saveUserConfig } from '../../utils/userConfig';
import ClientPagePreloader from './ClientPagePreloader';
import WorkflowsPage from './Workflows/Page';
import ClauseLibrary from './ClauseLibrary';
import ComparisonPage from './Comparison';
import ComparisonDetailPage from './ComparisonDetail';
import DomainUnreachableDialog from '../../components/UI/DomainUnreachableDialog';
import { fetchKnownDocTypes } from '../../api/config.api';
import GetInsightsPage from './GetInsights';
import InsightsTemplate from './InsightsTemplate';
import DefaultLayout from '../../components/UI/DefaultLayout';

const ClientPage = () => {
    const userData = getUserSessionData();
    const orgData = getUserConfig();
    const [isConfigLoaded, setIsConfigLoaded] = useState(false);
    const [unreachableDomain, setUnreachableDomain] = useState<string>('');
    const navigate = useNavigate();
    const [showDefaultLayout, setShowDefaultLayout] = useState(true);
    const location = useLocation();

    const { isExpired } = useJwt(userData?.accessToken as string);

    useEffect(() => {
        if (isExpired || isEmpty(userData)) return navigate('/login');
    }, [isExpired]);

    useEffect(() => {
        setShowDefaultLayout(!/\/client\/insights\/.*/g.test(location.pathname));
    }, [location]);

    async function loadOrg() {
        try {
            const metadata = await GetOrgMetadata();
            saveUserConfig(metadata);
            setIsConfigLoaded(true);
        } catch (e) {
            console.error('Something went wrong', e);
        }
    }

    async function pingServers() {
        try {
            await pingMeliorBE();
        } catch (e: any) {
            console.log('e', e);
            setUnreachableDomain(e.domain);
        }
    }

    async function pingMeliorBE() {
        try {
            await fetchKnownDocTypes();
        } catch (e: any) {
            throw { domain: orgData?.backendBaseUrl as string };
        }
    }

    useEffect(() => {
        pingServers();
        loadOrg();
    }, []);

    return (
        <>
            <DomainUnreachableDialog unreachableDomain={unreachableDomain} />
            {!isConfigLoaded && <ClientPagePreloader />}
            {isConfigLoaded && (
                <Routes>
                    {/* <Route path="/dashboard"  element={<DashboardPage />} /> */}

                    {/* Navigate to documents page temporarily */}
                    <Route path="/dashboard" element={<Navigate to="../documents" />} />
                    <Route
                        path="/insights"
                        element={<DefaultLayout children={<InsightsPage />} />}
                    />
                    <Route
                        path="/insights-template"
                        element={<DefaultLayout children={<InsightsTemplate />} />}
                    />
                    <Route
                        path="/saved-queries"
                        element={<DefaultLayout children={<SavedQueriesPage />} />}
                    />
                    <Route
                        path="/documents"
                        element={<DefaultLayout children={<DocumentsPage />} />}
                    />
                    <Route
                        path="/clause-library"
                        element={<DefaultLayout children={<ClauseLibrary />} />}
                    />
                    <Route
                        path="/comparisons"
                        element={<DefaultLayout children={<ComparisonPage />} />}
                    />
                    <Route
                        path="/comparison/:id"
                        element={<DefaultLayout children={<ComparisonDetailPage />} />}
                    />
                    <Route
                        path="/workflows"
                        element={<DefaultLayout children={<WorkflowsPage />} />}
                    />
                    <Route
                        path="/advanced-search"
                        element={<DefaultLayout children={<AdvancedSearchPage />} />}
                    />
                    <Route
                        path="/"
                        element={<DefaultLayout children={<Navigate to="/client/dashboard" />} />}
                    />
                    <Route path="/insights/:documentId" element={<GetInsightsPage />} />
                </Routes>
            )}
        </>
    );
};

export default ClientPage;
