import { useQuery } from '@tanstack/react-query';
import { fetchClauseLibrary } from '../api/clauseLibrary.api';

export function useClauseLibrary() {
    const { data, ...rest } = useQuery({
        queryFn: () => fetchClauseLibrary().then((res) => res),
        queryKey: ['clauses'],
        refetchOnWindowFocus: false,
    });

    return {
        clauseLibrary: data?.items,
        ...rest,
    };
}
