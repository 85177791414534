import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { IDocument } from '../../../../@types/Document';
import { useMutation } from '@tanstack/react-query';
import { fullyRemoveDocument } from '../../../../api/files.api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import Flex from 'styled-flex-component';
import { Text } from '../../../UI/Typography';
import { Theme } from '../../../../theme';
import { MeliorTranslate } from '../../../MeliorTranslate';

interface Props {
    documents: IDocument[];
    onClose: () => void;
    onDeleteSuccess: () => void;
    open: boolean;
}

export default function DeleteDocumentDialog(props: Props) {
    const { t } = useTranslation();
    const [deleteErrors, setDeleteErrors] = useState<string[]>([]);
    const { mutateAsync, isLoading } = useMutation(
        ['deleteDocument'],
        async (mutateParams: { documentName: string; documentId: string; boxFileId: string }) => {
            const { documentName, boxFileId } = mutateParams;
            try {
                await fullyRemoveDocument(boxFileId);
            } catch {
                setDeleteErrors(deleteErrors.concat(documentName));
            }
        }
    );

    const handleDelete = () => {
        Promise.all(
            props.documents.map((document) => {
                return mutateAsync({
                    documentName: document.name,
                    documentId: document.id ?? '',
                    boxFileId: document.box_details?.file_id as string,
                });
            })
        )
            .then(() => {
                if (!isEmpty(deleteErrors)) {
                    toast.error(
                        t(
                            props.documents.length > 1
                                ? 'Error deleting documents.'
                                : 'Error deleting document.'
                        )
                    );
                } else {
                    toast.success(
                        t(
                            props.documents.length > 1
                                ? 'Documents deleted successfully.'
                                : 'Document deleted successfully.'
                        )
                    );
                }
                props.onDeleteSuccess();
            })
            .catch(console.error.bind(console));
    };

    return (
        <Dialog open={props?.open}>
            <DialogTitle id="alert-dialog-title" style={{ fontSize: '18px' }}>
                {!isLoading && (
                    <MeliorTranslate
                        valueKey={
                            props.documents.length > 1
                                ? 'Are you sure you want to delete the following documents?'
                                : 'Are you sure you want to delete the following document?'
                        }
                    />
                )}
                <div
                    style={{
                        marginTop: '15px',
                        overflowY: 'auto',
                        maxHeight: '150px',
                        background: 'whitesmoke',
                        padding: '10px',
                        borderRadius: '10px',
                    }}
                >
                    {props.documents.map((document, index) => (
                        <li key={index} style={{ fontSize: '14px', marginTop: '5px' }}>
                            {document.name}
                        </li>
                    ))}
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {!isLoading ? (
                        <MeliorTranslate valueKey="This action cannot be undone." />
                    ) : (
                        <Flex alignCenter justifyCenter style={{ margin: '10px' }}>
                            <CircularProgress
                                size={15}
                                style={{ marginRight: '10px' }}
                                color="primary"
                            />
                            <Text color={Theme.primary} customFontSize={1}>
                                <MeliorTranslate
                                    valueKey={
                                        props.documents.length > 1
                                            ? 'Deleting documents...'
                                            : 'Deleting document...'
                                    }
                                />
                            </Text>
                        </Flex>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {!isLoading && (
                    <>
                        <Button onClick={props.onClose}>
                            <MeliorTranslate valueKey="Cancel" />
                        </Button>
                        <Button onClick={handleDelete} autoFocus color="error" disabled={isLoading}>
                            <MeliorTranslate valueKey="Delete" />
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
}
