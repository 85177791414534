import { Box, Button, Checkbox, FormControlLabel, Modal, TextField } from '@mui/material';
import Container from '../../UI/Common/Container';
import { H5, Text } from '../../UI/Typography';
import Flex from 'styled-flex-component';
import { CloseOutlined } from '@mui/icons-material';
import SearchSectionFilterChip from '../FilterChip';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import {
    AdvancedSearchContext,
    AdvancedSearchContextType,
} from '../../../pages/Client/AdvancedSearch/context/advancedSearchContext';
import { MeliorTranslate } from '../../MeliorTranslate';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: '10px',
};

interface ISaveQueryModalProps {
    isOpen: boolean;
    isExactMatch: boolean;
    setIsOpen: (val: boolean) => void;
    onSave: (queryName: string, queryDescription: string) => void;
}

export default function SaveQueryModal({
    isOpen,
    isExactMatch,
    setIsOpen,
    onSave,
}: ISaveQueryModalProps) {
    const [queryName, setQueryName] = useState<string>('');
    const [queryDescription, setQueryDescription] = useState<string>('');
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const { searchText, filtersAdded } = useContext(
        AdvancedSearchContext
    ) as AdvancedSearchContextType;

    async function createQuery() {
        setIsSaving(true);

        try {
            await onSave(queryName, queryDescription);
            setIsSaving(false);
            setIsOpen(false);
        } catch (e) {
            setIsSaving(false);
            toast.error('Something went wrong.');
        }
    }
    return (
        <Modal
            open={isOpen}
            aria-labelledby="save-query-modal-title"
            aria-describedby="save-query-modal-description"
        >
            <Box sx={style}>
                <>
                    <Flex justifyBetween alignTop>
                        <Container>
                            <Container bottomOuterSpacing={0.75}>
                                <H5>Create query</H5>
                            </Container>
                        </Container>
                        <Container onClick={() => setIsOpen(false)}>
                            <CloseOutlined
                                sx={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }}
                            />
                        </Container>
                    </Flex>
                    <Container topOuterSpacing={1}>
                        <Flex>
                            <Box width="50%">
                                <Container bottomOuterSpacing={1}>
                                    <TextField
                                        required
                                        autoComplete="off"
                                        fullWidth
                                        id="query-name"
                                        label="Name"
                                        placeholder="Query name"
                                        onChange={(e) => setQueryName(e.target.value)}
                                        value={queryName}
                                    />
                                </Container>
                                <Container>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        multiline
                                        fullWidth
                                        autoComplete="off"
                                        placeholder="Add description"
                                        onChange={(e) => setQueryDescription(e.target.value)}
                                        value={queryDescription}
                                        rows={8}
                                    />
                                </Container>
                            </Box>
                            <Box width="50%" marginLeft={4}>
                                <Container>
                                    <TextField
                                        required
                                        fullWidth
                                        id="search-text"
                                        label="Query"
                                        disabled
                                        autoComplete="off"
                                        value={searchText}
                                    />
                                    <FormControlLabel
                                        sx={{ fontSize: '13px' }}
                                        control={
                                            <Checkbox
                                                size="small"
                                                checked={isExactMatch}
                                                disabled
                                            />
                                        }
                                        label={
                                            <Text customFontSize={0.8125} customletterSpacing={0}>
                                                <MeliorTranslate valueKey="exact matches only" />
                                            </Text>
                                        }
                                    />
                                    <Container maxHeight="300px" overflow="auto">
                                        <Flex justifyEvenly wrap row>
                                            {filtersAdded.map((clause, index) => (
                                                <Container
                                                    rightOuterSpacing={0.5625}
                                                    topOuterSpacing={0.25}
                                                    bottomOuterSpacing={0.25}
                                                >
                                                    <SearchSectionFilterChip
                                                        index={index}
                                                        clause={clause}
                                                        setIsFiltersOverlayOpened={() => {}}
                                                    />
                                                </Container>
                                            ))}
                                        </Flex>
                                    </Container>
                                </Container>
                            </Box>
                        </Flex>
                    </Container>
                    <Container topInnerSpacing={1}>
                        <Flex justifyEnd>
                            <Container>
                                <Button onClick={() => setIsOpen(false)} disabled={isSaving}>
                                    <Text
                                        customFontFamily="Poppins"
                                        customFontWeight={500}
                                        uppercase
                                    >
                                        <MeliorTranslate valueKey="Cancel" />
                                    </Text>
                                </Button>
                            </Container>
                            <Container leftOuterSpacing={0.5}>
                                <Button
                                    variant="contained"
                                    onClick={createQuery}
                                    disabled={isSaving || !queryName}
                                >
                                    <Text
                                        customFontFamily="Poppins"
                                        customFontWeight={500}
                                        uppercase
                                    >
                                        Create
                                    </Text>
                                </Button>
                            </Container>
                        </Flex>
                    </Container>
                </>
            </Box>
        </Modal>
    );
}
