import { useCallback, useEffect, useState } from 'react';
import {
    createComparisonTask,
    getComparison,
    getComparisonExportFile,
} from '../../../api/comparison.api';
import { useParams } from 'react-router-dom';
import { IComparisonDetail, IComparisonResult } from '../../../@types/Comparison';
import Flex from 'styled-flex-component';
import Container from '../../../components/UI/Common/Container';
import { Text } from '../../../components/UI/Typography';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ComparisonResultTable from '../../../components/ComparisonDetail/Tables';
import { Close, DownloadOutlined, Fullscreen, FullscreenExit } from '@mui/icons-material';
import { useClauses } from '../../../hooks/useClauses';
import { toast } from 'react-toastify';
import { useSavedQueries } from '../../../hooks/useSavedQueries';
import CreateComparisonModal from '../../../components/Comparisons/Tables/CreateComparisonModal';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import PageLoadingPlaceholder from '../../../components/PageLoadingPlaceholder/PageLoadingPlaceholder';
import FileSaver from 'file-saver';
import { useMutation } from '@tanstack/react-query';
import DocumentLink from '../../../components/UI/DocumentLink';
import { IDocument } from '../../../@types/Document';

const ComparisonDetailPage = () => {
    const [comparisonDetails, setComparisonDetails] = useState<IComparisonDetail>();
    const routeParams = useParams();
    const comparisonId = routeParams['id'];
    const [results, setResults] = useState<IComparisonResult>();
    const [comparisonClauses, setComparisonClauses] = useState<Array<string>>([]);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const { clauses } = useClauses();
    const [clauseList, setClauseList] = useState<string[]>([]);
    const [queries, setQueries] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [selectedQuery, setSelectedQuery] = useState('');
    const [isHighlighted, setIsHighlighted] = useState(true);
    const [isLoadingComparison, setIsLoadingComparison] = useState(true);
    const [comparisonDefault, setComparisonDefault] = useState<{}>();
    const table = useFullScreenHandle();
    const [isFullscreen, setIsFullscreen] = useState(false);
    const { savedQueries, refetch } = useSavedQueries(
        {
            page: page + 1,
            size: rowsPerPage,
        },
        'saved-queries'
    );

    useEffect(() => {
        getComparisonData();
    }, []);

    useEffect(() => {
        let names = savedQueries.map((query) => query.query_name);
        setQueries(names);
        setSelectedQuery(names[0]);
    }, []);

    useEffect(() => {
        if (clauses && clauses.length) {
            setClauseList(clauses);
        }
    }, [clauses]);

    const reportChange = useCallback(
        (state, handle) => {
            if (handle === table) {
                setIsFullscreen(state);
            }
        },
        [table]
    );

    async function onCreateQuery(task) {
        await createComparisonTask(task).then((res) => {
            if (res) {
                toast.success(`Successfully updated comparison task`);
                refetch();
            }
        });
    }

    const getComparisonData = () => {
        getComparison(comparisonId as string).then((res) => {
            setIsLoadingComparison(true);
            setComparisonDetails(res);
            setSelectedQuery(res.metadata.saved_query);
            let comparisonContainer: any = [];
            res.comparisons.forEach((comparison: any) => {
                const keys = Object.keys(comparison).sort();
                const keyClauses = keys.filter((key) => key != 'doc_id' && key != 'doc_name');

                if (!comparisonClauses.length) {
                    setComparisonClauses(keyClauses);
                    setComparisonDefault({
                        name: res.name,
                        description: res.description,
                        clauses: keyClauses,
                        saved_query: res.metadata.saved_query,
                        id: res.id,
                    });
                }

                let resultsContainer: any = [];
                keyClauses.forEach((clause) => {
                    if (comparison[clause] != null)
                        resultsContainer.push({
                            clause: clause,
                            diff: comparison[clause].diff,
                            entailment: comparison[clause].entailment,
                            paraphrasing: comparison[clause].paraphrasing,
                        });
                });
                comparisonContainer.unshift({
                    doc_id: comparison.doc_id,
                    doc_name: comparison.doc_name,
                    results: resultsContainer,
                });
                resultsContainer = [];
            });
            setResults(comparisonContainer);
            setIsLoadingComparison(false);
        });
    };

    const exportComparison = async () => {
        await mutateAsync(comparisonId as string);
    };

    const { mutateAsync, isLoading } = useMutation(['exportFile'], async (comparisonId: string) => {
        try {
            const blob = await getComparisonExportFile(comparisonId);
            FileSaver.saveAs(blob, `${comparisonDetails?.name}.xlsx`);
            toast.success('Comparison exported successfully');
        } catch {
            toast.error('Unable to export comparison.');
        }
    });

    if (isLoadingComparison) {
        return (
            <Box
                sx={{
                    width: '100%',
                    height: '85vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <PageLoadingPlaceholder />
            </Box>
        );
    }

    return (
        <Box>
            {/* Params Section */}
            <Box sx={{ marginBottom: '20px', display: 'flex' }}>
                {/* Comparison Clauses */}
                {comparisonClauses && (
                    <Box sx={{ width: '50%', display: 'flex' }}>
                        <Box
                            sx={{
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: '15px',
                            }}
                        >
                            <Text>Your Clauses</Text>
                        </Box>
                        <Box
                            sx={{
                                width: '75%',
                            }}
                        >
                            <CreateComparisonModal
                                isOpen={isEditing}
                                setIsOpen={setIsEditing}
                                onSave={(task) => {
                                    onCreateQuery(task);
                                }}
                                comparisonDefault={comparisonDefault}
                            />
                            <Box
                                sx={{
                                    border: '1px solid lightgray',
                                    borderRadius: '5px',
                                }}
                            >
                                <Box
                                    sx={{
                                        paddingX: (theme) => theme.spacing(0.5),
                                        paddingY: (theme) => theme.spacing(1),
                                        display: 'flex',
                                        gap: (theme) => theme.spacing(1),
                                        flexWrap: 'wrap',
                                        overflowY: 'auto',
                                        overflowX: 'hidden',
                                        height: '70px',
                                    }}
                                >
                                    {comparisonClauses.map((clause, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                borderRadius: '10px',
                                                height: '20px',
                                                background: 'rgba(16, 185, 129, 0.3)',
                                                paddingRight: '10px',
                                                paddingLeft: '10px',
                                                marginLeft: '5px',
                                                fontSize: '14px',
                                                alignItems: 'center',
                                                display: 'flex',
                                            }}
                                        >
                                            <p style={{ marginRight: '5px' }}>{clause}</p>
                                            <Close
                                                fontSize="small"
                                                onClick={() => setIsEditing(true)}
                                                sx={{ color: 'rgba(13, 24, 50, 0.54)' }}
                                            />
                                        </div>
                                    ))}
                                </Box>
                                <Flex justifyBetween alignCenter>
                                    <Box
                                        sx={{
                                            background: 'lightgray',
                                            borderRadius: '10px',
                                            width: '20px',
                                            height: '20px',
                                            marginLeft: '10px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Text customFontSize={0.8}>{comparisonClauses.length}</Text>
                                    </Box>
                                    <Button
                                        onClick={() => {
                                            setIsEditing(true);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </Flex>
                            </Box>
                        </Box>
                    </Box>
                )}
                {/* Saved Query */}
                {comparisonDetails?.metadata?.saved_query && (
                    <Box sx={{ width: '50%', marginLeft: '20px', display: 'flex' }}>
                        <Box
                            sx={{
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: '15px',
                            }}
                        >
                            <Text>Your Saved Query</Text>
                        </Box>
                        <Container width={'65%'} leftOuterSpacing={0.5} rightOuterSpacing={1}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                size="small"
                                options={queries}
                                sx={{ width: '100%' }}
                                value={selectedQuery}
                                renderInput={(params) => (
                                    <TextField
                                        {...(params as any)}
                                        onClick={() => {
                                            setIsEditing(true);
                                        }}
                                    />
                                )}
                            />
                        </Container>
                    </Box>
                )}
            </Box>
            <FullScreen handle={table} onChange={reportChange}>
                <Box
                    sx={{
                        background: isFullscreen ? 'white' : '',
                        height: isFullscreen ? '100vh' : '',
                        paddingTop: isFullscreen ? '10px' : '0px',
                        overflowY: 'auto',
                    }}
                >
                    {/* Table Info */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            marginY: isFullscreen ? '0px' : '10px',
                        }}
                    >
                        {comparisonDetails?.comparisons && (
                            <Text
                                style={{
                                    marginRight: '10px',
                                    borderRadius: '50px',
                                    background: 'aliceblue',
                                    padding: '8px',
                                }}
                            >
                                {comparisonDetails?.comparisons.length} document
                                {comparisonDetails?.comparisons.length > 1 ? 's' : ''} compared
                            </Text>
                        )}
                        <Flex justifyContent alignCenter>
                            <Switch
                                sx={{ marginRight: '5px' }}
                                size="small"
                                checked={isHighlighted}
                                onChange={() => {
                                    setIsHighlighted(!isHighlighted);
                                }}
                            />
                            <Text>Highlight Clause Difference</Text>
                        </Flex>
                        {isLoading ? (
                            <div
                                style={{
                                    paddingLeft: '15px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <CircularProgress size={20} />
                                <Text color="black" style={{ marginLeft: '10px' }}>
                                    Exporting
                                </Text>
                            </div>
                        ) : (
                            <Button onClick={exportComparison} style={{ marginLeft: '5px' }}>
                                <DownloadOutlined />
                                <Text color="black" style={{ marginLeft: '5px' }}>
                                    Export
                                </Text>
                            </Button>
                        )}
                        {!isFullscreen && (
                            <Button onClick={table.enter}>
                                <Fullscreen />{' '}
                                <Text color="black" style={{ marginLeft: '5px' }}>
                                    Enter fullscreen
                                </Text>
                            </Button>
                        )}
                        {isFullscreen && (
                            <Button onClick={table.exit}>
                                <FullscreenExit />{' '}
                                <Text color="black" style={{ marginLeft: '5px' }}>
                                    Exit fullscreen
                                </Text>
                            </Button>
                        )}
                    </Box>
                    {/* Results Accordion */}
                    <Box
                        sx={{
                            background: '#ffffff',
                            boxShadow: ' 0px 3px 6px rgba(3, 138, 255, 0.1)',
                            borderRadius: '12px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                        }}
                    >
                        {comparisonDetails?.comparisons && (
                            <>
                                {comparisonDetails.comparisons.map((comparison: any, index) => (
                                    <Accordion
                                        key={index}
                                        elevation={0}
                                        disableGutters
                                        defaultExpanded={index == 0}
                                    >
                                        <AccordionSummary
                                            style={{ height: '38px' }}
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            {results && (
                                                <DocumentLink
                                                    document={
                                                        {
                                                            id: results[index].doc_id,
                                                            name: results[index].doc_name,
                                                        } as IDocument
                                                    }
                                                />
                                            )}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {results && (
                                                <ComparisonResultTable
                                                    comparisonResults={results[index]}
                                                    isHighlighted={isHighlighted}
                                                />
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </>
                        )}
                    </Box>
                </Box>
            </FullScreen>
        </Box>
    );
};

export default ComparisonDetailPage;
