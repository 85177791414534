import Container from '../../../components/UI/Common/Container';
import { Badge, Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Text } from '../../../components/UI/Typography';
import { Button, Grid } from '@mui/material';
import { Theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import SearchBox from '../../../components/UI/Navbar/SearchBox';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { isEmpty } from 'lodash';
import Flex from 'styled-flex-component';
import { Add, DeleteOutline } from '@mui/icons-material';
import { useDocumentTypes } from '../../../hooks/useDocumentTypes';
import { deleteTemplate, getTemplates } from '../../../api/template.api';
import { useClauseLibrary } from '../../../hooks/useClauseLibrary';
import { IClause } from '../../../@types/SavedQuery';
import { toast } from 'react-toastify';
import DeleteModal from '../../../components/UI/Common/Modal/DeleteModal';
import { ITemplate } from '../../../@types/Template';
import CreateListModal from '../../../components/Templates/CreateListModal';
import HiddenInsightsBadge from '../../../components/UI/Badge/HiddenInsightsBadge';
import Loading from '../../../components/UI/Common/Loading';
import { MeliorTranslate } from '../../../components/MeliorTranslate';

const InsightsTemplate = () => {
    const { t } = useTranslation();
    const { isFetching, clauseLibrary } = useClauseLibrary();
    const [templates, setTemplates] = useState<ITemplate[]>([]);
    const [initialTemplates, setInitialTemplates] = useState<any[]>([]);
    const [hoveredTemplate, setHoveredTemplate] = useState<number>(); //template index
    const [selectedTemplate, setSelectedTemplate] = useState<number>(-1); //template index
    const [isLoadingTypes, setIsLoadingTypes] = useState(true);
    const [allClauses, setAllClauses] = useState<string[]>([]);
    const [hiddenClauses, setHiddenClauses] = useState<string[]>([]);
    const { docTypes } = useDocumentTypes();
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [mode, setMode] = useState<string>('new');

    useEffect(() => {
        getTemplates().then((res) => {
            setIsLoadingTypes(false);
            const display = res.reverse(); //res is reversed to display latest on top
            setTemplates(display);
            setInitialTemplates(display);
            if (res.length) setSelectedTemplate(0);
        });
    }, [docTypes]);

    useEffect(() => {
        if (clauseLibrary) setAllClauses(clauseLibrary.map((item: IClause) => item.name));
    }, [isFetching]);

    useEffect(() => {
        if (selectedTemplate > -1 && templates.length) {
            const visible: string[] = templates[selectedTemplate].visible;
            setHiddenClauses(allClauses.filter((clause) => !visible.includes(clause)));
        }
    }, [allClauses, selectedTemplate, templates.length]);

    const doDelete = async () => {
        const currentTemplateName = templates[selectedTemplate].template_name;
        await deleteTemplate(currentTemplateName).then((res) => {
            if (res) {
                const updatedTemplates = templates.filter(
                    (template) => template.template_name != currentTemplateName
                );
                setTemplates(updatedTemplates);
                setInitialTemplates(updatedTemplates.concat([]));
                if (updatedTemplates.length) setSelectedTemplate(0);
                setIsDeleting(false);
                toast.success('Template deleted successfully.');
            }
        });
    };

    const doSearch = (searchKey) => {
        const filteredTemplates = searchKey.length
            ? initialTemplates.filter(
                  (template) =>
                      template.template_name.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
              )
            : initialTemplates;
        setIsSearching(searchKey.length > 0);
        setTemplates(filteredTemplates);
        setSelectedTemplate(filteredTemplates.length ? 0 : -1);
        if (!filteredTemplates.length) {
            setHiddenClauses([]);
        }
    };

    const onSaveTemplateSuccess = (template: ITemplate) => {
        setIsOpen(false);
        if (mode == 'edit') {
            templates[selectedTemplate] = template;
            return;
        }
        setTemplates([template].concat(templates));
        setInitialTemplates([template].concat(templates));
    };

    return (
        <Container>
            <Grid container justifyContent="space-between" sx={{ marginBottom: 2 }}>
                <SearchBox
                    placeholder={t('Search insight lists')}
                    onChange={(searchKey) => doSearch(searchKey)}
                />
                <Button variant="contained" startIcon={<Add />}>
                    <Text
                        uppercase
                        color={Theme.whiteColor}
                        customletterSpacing={0.05}
                        onClick={() => {
                            setMode('new');
                            setIsOpen(true);
                        }}
                    >
                        <MeliorTranslate valueKey="Create List" />
                    </Text>
                </Button>
            </Grid>
            {isEmpty(templates) && !isSearching ? (
                <div
                    style={{
                        height: '80vh',
                        borderRadius: '14px',
                        background: 'white',
                        padding: 30,
                        paddingLeft: 20,
                    }}
                >
                    <Flex alignCenter>
                        <Typography marginRight={2.5}>
                            <MeliorTranslate valueKey="Your Insight Lists" />
                        </Typography>
                    </Flex>
                    {isLoadingTypes ? (
                        <Loading message="Loading templates" />
                    ) : (
                        <Flex justifyCenter alignCenter style={{ height: '60vh' }}>
                            <Flex column>
                                <Typography fontSize={14}>
                                    <MeliorTranslate valueKey="You have no Insight Template yet." />
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{ marginTop: 10 }}
                                    startIcon={<Add />}
                                    onClick={() => {
                                        setMode('new');
                                        setIsOpen(true);
                                    }}
                                >
                                    <Text
                                        uppercase
                                        color={Theme.whiteColor}
                                        customletterSpacing={0.05}
                                    >
                                        <MeliorTranslate valueKey="Create your first one" />
                                    </Text>
                                </Button>
                            </Flex>
                        </Flex>
                    )}
                </div>
            ) : (
                <PanelGroup autoSaveId="templateList" direction="horizontal">
                    <Panel defaultSize={20} minSize={20} order={1}>
                        <div style={{ height: '80vh', borderRadius: '14px', background: 'white' }}>
                            <Box>
                                <Flex alignCenter style={{ padding: 30, paddingLeft: 20 }}>
                                    <Typography marginRight={2.5}>
                                        <MeliorTranslate valueKey="Your Insight Lists" />
                                    </Typography>
                                    <Badge badgeContent={templates.length} color="primary" />
                                </Flex>
                                <div
                                    style={{
                                        maxHeight: '70vh',
                                        overflowY: 'auto',
                                        overflowX: 'hidden',
                                    }}
                                >
                                    {!(isSearching && !templates.length) && (
                                        <>
                                            {templates.map((template: any, index) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        cursor: 'pointer',
                                                        background:
                                                            selectedTemplate == index
                                                                ? Theme.highlight
                                                                : hoveredTemplate == index
                                                                ? 'whitesmoke'
                                                                : 'white',
                                                        padding: '20px',
                                                        border: '1px solid whitesmoke',
                                                        borderBottom: 'none',
                                                    }}
                                                    onClick={() => setSelectedTemplate(index)}
                                                    onMouseEnter={() => setHoveredTemplate(index)}
                                                    onMouseLeave={() => setHoveredTemplate(-1)}
                                                >
                                                    <Typography sx={{ fontSize: 14 }} gutterBottom>
                                                        {template.template_name}
                                                    </Typography>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </Box>
                        </div>
                    </Panel>
                    <PanelResizeHandle style={{ width: '5px' }} />
                    <Panel order={2}>
                        <div
                            style={{
                                height: '80vh',
                                borderRadius: '14px',
                                background: 'white',
                            }}
                        >
                            {/* Template Detail View */}
                            {!(isSearching && !templates.length) && (
                                <Box>
                                    <Flex
                                        justifyEnd
                                        alignCenter
                                        style={{
                                            gap: '10px',
                                            padding: '20px',
                                        }}
                                    >
                                        {/* Share */}
                                        <Button variant="contained" disabled={true}>
                                            <Text
                                                uppercase
                                                color={Theme.whiteColor}
                                                customletterSpacing={0.05}
                                            >
                                                <MeliorTranslate valueKey="Share" />
                                            </Text>
                                        </Button>
                                        {/* Edit */}
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                setMode('edit');
                                                setIsOpen(true);
                                            }}
                                        >
                                            <Text
                                                uppercase
                                                // color={Theme.whiteColor}
                                                customletterSpacing={0.05}
                                            >
                                                <MeliorTranslate valueKey="Edit" />
                                            </Text>
                                        </Button>
                                        {/* Delete */}
                                        <Button
                                            variant="text"
                                            onClick={() => {
                                                setIsDeleting(true);
                                            }}
                                        >
                                            <DeleteOutline />
                                        </Button>
                                    </Flex>
                                    <Flex
                                        column
                                        justifyCenter
                                        style={{ gap: '25px', padding: '30px', paddingTop: 0 }}
                                    >
                                        <Text
                                            style={{
                                                height: '6vh',
                                                overflowY: 'auto',
                                                marginTop: '10px',
                                            }}
                                        >
                                            {templates[selectedTemplate].template_description}
                                        </Text>
                                        <Flex
                                            style={{
                                                height: '28vh',
                                                gap: '20px',
                                                width: '100%',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <div style={{ width: '50%' }}>
                                                {/* Label & Count */}
                                                <Flex alignCenter>
                                                    <Text customFontWeight={600}>
                                                        <MeliorTranslate valueKey="Displayed Insights" />
                                                    </Text>
                                                    <Badge
                                                        badgeContent={
                                                            templates[selectedTemplate].visible
                                                                .length
                                                        }
                                                        sx={{ margin: 2 }}
                                                        color="primary"
                                                    />
                                                </Flex>
                                                <Flex
                                                    column
                                                    style={{
                                                        height: '24vh',
                                                        overflowY: 'auto',
                                                        gap: '5px',
                                                        marginTop: '5px',
                                                        background: 'whitesmoke',
                                                    }}
                                                >
                                                    {templates[selectedTemplate].visible.map(
                                                        (clause, index) => (
                                                            <Flex
                                                                alignCenter
                                                                justifyBetween
                                                                style={{
                                                                    minHeight: '50px',
                                                                    border: '1px solid lightgray',
                                                                    borderRadius: '5px',
                                                                    paddingLeft: '10px',
                                                                    paddingRight: '10px',
                                                                    background: 'white',
                                                                }}
                                                                key={index}
                                                            >
                                                                <Text color={Theme.primaryDark}>
                                                                    {clause}
                                                                </Text>
                                                            </Flex>
                                                        )
                                                    )}
                                                </Flex>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                {/* Label & Count */}
                                                <Flex
                                                    alignCenter
                                                    style={{ margin: 10, marginLeft: 0 }}
                                                >
                                                    <Text
                                                        customFontWeight={600}
                                                        style={{ marginRight: 16 }}
                                                    >
                                                        <MeliorTranslate valueKey="Hidden Insights" />
                                                    </Text>
                                                    <HiddenInsightsBadge
                                                        content={hiddenClauses.length}
                                                    />
                                                </Flex>
                                                <Flex
                                                    column
                                                    style={{
                                                        height: '24vh',
                                                        overflowY: 'auto',
                                                        gap: '5px',
                                                        marginTop: '5px',
                                                        background: 'whitesmoke',
                                                    }}
                                                >
                                                    {hiddenClauses.map((clause, index) => (
                                                        <Flex
                                                            alignCenter
                                                            justifyBetween
                                                            style={{
                                                                minHeight: '50px',
                                                                border: '1px solid lightgray',
                                                                borderRadius: '5px',
                                                                paddingLeft: '10px',
                                                                paddingRight: '10px',
                                                                background: 'white',
                                                            }}
                                                            key={index}
                                                        >
                                                            <Text color={Theme.primaryDark}>
                                                                {clause}
                                                            </Text>
                                                        </Flex>
                                                    ))}
                                                </Flex>
                                            </div>
                                        </Flex>
                                        <div
                                            style={{
                                                height: '28vh',
                                                width: '100%',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            {/* Label & Count */}
                                            <Flex alignCenter>
                                                <Text customFontWeight={600}>
                                                    <MeliorTranslate valueKey="Document Types" />
                                                </Text>
                                                <Badge
                                                    badgeContent={
                                                        templates[selectedTemplate].linked_doc_types
                                                            .length
                                                    }
                                                    sx={{ margin: 2 }}
                                                    color="primary"
                                                />
                                            </Flex>
                                            <Flex
                                                column
                                                style={{
                                                    height: '24vh',
                                                    overflowY: 'auto',
                                                    gap: '5px',
                                                    marginTop: '5px',
                                                    background: 'whitesmoke',
                                                }}
                                            >
                                                {templates[selectedTemplate].linked_doc_types.map(
                                                    (type, index) => (
                                                        <Flex
                                                            alignCenter
                                                            justifyBetween
                                                            style={{
                                                                minHeight: '50px',
                                                                border: '1px solid lightgray',
                                                                borderRadius: '5px',
                                                                paddingLeft: '10px',
                                                                paddingRight: '10px',
                                                                background: 'white',
                                                            }}
                                                            key={index}
                                                        >
                                                            <Text color={Theme.primaryDark}>
                                                                {type}
                                                            </Text>
                                                        </Flex>
                                                    )
                                                )}
                                            </Flex>
                                        </div>
                                    </Flex>
                                </Box>
                            )}
                        </div>
                    </Panel>
                </PanelGroup>
            )}
            <DeleteModal
                open={isDeleting}
                itemToDelete={'this insight template'}
                onCancel={() => setIsDeleting(false)}
                onConfirm={() => doDelete()}
            />
            <CreateListModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                clauses={clauseLibrary?.map((item: IClause) => item.name)}
                onSaveTemplateSuccess={(template) => onSaveTemplateSuccess(template)}
                maxStep={4}
                mode={mode}
                template={templates[selectedTemplate]}
            />
        </Container>
    );
};

export default InsightsTemplate;
