import GetInsightsSidebarStyled from './Sidebar.styled';
import { Box, Tab, Tabs } from '@mui/material';
import { TabPanel } from '../../UI/Common/Tabs/Tabs';
import ClausesList from './ClausesList';
import KeyDataList from './KeyDataList';
import { InsightType } from '../../../enums/InsightType';
import { Theme } from '../../../theme';
import ChatLLM from './ChatLLM';
import { useContext } from 'react';
import {
    GetInsightsContext,
    GetInsightsContextType,
} from '../../../pages/Client/GetInsights/context/getInsightsContext';
import { MeliorTranslate } from '../../MeliorTranslate';

interface IInsightsSidebarProps {
    getDocument: () => void;
    sidebarSize: number;
    newDocType: string;
}

const tabsIndices = {
    [InsightType.CLAUSE]: 0,
    [InsightType.KEYDATA]: 1,
    [InsightType.CHAT]: 2,
};

const InsightsSidebar = ({ ...props }: IInsightsSidebarProps) => {
    const { selectedInsightType, setSelectedInsightType, setSelectedInsight } = useContext(
        GetInsightsContext
    ) as GetInsightsContextType;
    const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue === 0) {
            setSelectedInsightType(InsightType.CLAUSE);
        }
        if (newValue === 1) {
            setSelectedInsightType(InsightType.KEYDATA);
        }
        if (newValue === 2) {
            setSelectedInsightType(InsightType.CHAT);
        }

        setSelectedInsight(undefined);
    };

    const tabIndex = tabsIndices[selectedInsightType];

    return (
        <GetInsightsSidebarStyled>
            <Box
                sx={{
                    borderBottom: 1,
                    paddingTop: 1,
                    paddingLef: 1,
                    paddingRight: 1,
                    borderColor: 'divider',
                }}
            >
                <Tabs
                    value={tabIndex}
                    onChange={handleTabsChange}
                    style={{ overflow: 'visible' }}
                    variant="fullWidth"
                >
                    <Tab
                        sx={{ color: Theme.primaryDark, fontFamily: 'Inter' }}
                        label={<MeliorTranslate valueKey="Insights" />}
                        key="insights"
                    />
                    <Tab
                        sx={{ color: Theme.primaryDark, fontFamily: 'Inter' }}
                        label={<MeliorTranslate valueKey="Key Data" />}
                        key="keyData"
                    />
                    <Tab
                        sx={{ color: Theme.primaryDark, fontFamily: 'Inter' }}
                        label={<MeliorTranslate valueKey="Chat" />}
                        key="chat"
                    />
                </Tabs>
            </Box>
            <TabPanel value={tabIndex} index={0}>
                <ClausesList {...props} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <KeyDataList />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
                <ChatLLM />
            </TabPanel>
        </GetInsightsSidebarStyled>
    );
};

export default InsightsSidebar;
