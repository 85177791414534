import {
    Autocomplete,
    Badge,
    Box,
    Button,
    Chip,
    Input,
    Modal,
    TextField,
    Tooltip,
    createFilterOptions,
} from '@mui/material';
import { H5 } from '../../UI/Typography';
import Container from '../../UI/Common/Container';
import Flex from 'styled-flex-component';
import { CloseOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../theme';
import { ITag, ITagItem } from '../../../@types/Tag';
import { addTags, deleteTags, getTags } from '../../../api/tags';
import { toast } from 'react-toastify';
import { MuiColorInput } from 'mui-color-input';
import { tagArrayToObject, tagsToArray } from '../../../utils/transformTags';
import { isEmpty } from 'lodash';
import { MeliorTranslate } from '../../MeliorTranslate';

interface IAddTagModalProps {
    documentTags: Object;
    documentIds: string[];
    isOpen: boolean;
    setIsOpen: (val: boolean) => void;
    onSaveTagsSuccess: (tags: ITagItem[], action: string) => void;
    mode: string;
    allTags: ITagItem[];
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: '10px',
};

export default function AddTagModal({
    isOpen,
    documentTags,
    documentIds,
    setIsOpen,
    onSaveTagsSuccess,
    mode,
    allTags,
}: IAddTagModalProps) {
    const defaultTagColor = '#ADD8E6';
    const { t } = useTranslation();
    const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState('');
    const [tagColor, setTagColor] = useState(defaultTagColor);
    const [tags, setTags] = useState<Array<ITagItem>>([]);
    const [deletedTags, setDeletedTags] = useState<string[]>([]);
    const [tag, setTag] = useState<string>('');
    const [highlightedTag, setHighlightedTag] = useState<string>('');

    const filterOptions = createFilterOptions({
        ignoreCase: false,
    });

    useEffect(() => {
        setTags(mode == 'adding' ? [] : tagsToArray(documentTags));
    }, [mode, documentTags]);

    const scrollToDisplayBottom = () => {
        const element: any = document.getElementById('displayed-tags-bottom');
        element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    };

    const saveTags = () => {
        let hasAlreadyShowUpdateMsg = false;
        if (hasAdded()) {
            const tagList: ITag = { doc_ids: documentIds, tags: tagArrayToObject(tags) };
            addTags(tagList).then((res) => {
                if (res) {
                    toast.success(
                        t(`tag.modal.msg.${mode == 'updating' ? 'updated' : 'saved'}Success`)
                    );
                    hasAlreadyShowUpdateMsg = true;
                    onSaveTagsSuccess(tags, 'add');
                    if (!hasDeleted()) resetModal();
                } else {
                    toast.error(t(`Error saving tags.`));
                }
            });
        }
        if (hasDeleted()) {
            const tagList: ITag = { doc_ids: documentIds, tags: deletedTags };
            deleteTags(tagList).then((res) => {
                if (res) {
                    if (!hasAlreadyShowUpdateMsg) {
                        toast.success(
                            t(`tag.modal.msg.${mode == 'updating' ? 'updated' : 'deleted'}Success`)
                        );
                    }
                    onSaveTagsSuccess(
                        deletedTags.map((tag) => {
                            return { name: tag, color: '' };
                        }),
                        'delete'
                    );
                    resetModal();
                } else {
                    toast.error(t(`Error deleting tags.`));
                }
            });
        }
    };

    const hasDeleted = () => {
        return Boolean(deletedTags.length);
    };

    const hasAdded = () => {
        if (!tags.length) return false;

        //check if deleted tags have not been reinputted
        const docTags =
            mode == 'adding' ? [] : tagsToArray(documentTags).map((docTag) => docTag.name);
        const newTags = tags.filter((tag) => !docTags.includes(tag.name));

        return Boolean(newTags.length);
    };

    const setCurrTag = (tag) => {
        setTag(tag);
        setIsDuplicate(tags.map((newTag) => newTag.name).includes(tag));
        setInputValue(tag);

        // if existing, set tag's color
        const isExistingTag = allTags.find((docTag) => docTag.name === tag);
        setTagColor(
            isExistingTag
                ? allTags.find((existingTag) => existingTag.name == tag)?.color ?? tagColor
                : tagColor
        );
    };

    const cancelAction = () => {
        setIsOpen(false);
        resetModal();
    };

    const resetModal = () => {
        setTagColor(defaultTagColor);
        setInputValue('');
        setIsDuplicate(false);
        setDeletedTags([]);
        setTags(mode == 'adding' ? [] : tagsToArray(documentTags));
    };

    const isExistingTag = (tag: string) => {
        return tagsToArray(documentTags)
            .map((tag) => tag.name)
            .includes(tag);
    };

    const sanitizeDeleted = (tag: string) => {
        const index = deletedTags.findIndex((deleted) => deleted == tag);
        if (index > -1) deletedTags.splice(index, 1);
    };

    return (
        <Modal open={isOpen} aria-labelledby="add-tag-title" aria-describedby="add-tag-description">
            <Box sx={style}>
                <Flex justifyBetween alignTop>
                    <Flex alignCenter>
                        <H5>
                            <MeliorTranslate
                                valueKey={
                                    mode == 'updating'
                                        ? 'Edit Tags'
                                        : mode == 'adding'
                                        ? 'Add Tags'
                                        : 'Remove Tags'
                                }
                            />
                        </H5>
                        <Container leftOuterSpacing={1.5}>
                            <Badge badgeContent={tags.length} color="primary" />
                        </Container>
                    </Flex>
                    <Container onClick={() => cancelAction()}>
                        <CloseOutlined sx={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }} />
                    </Container>
                </Flex>
                <div
                    style={{
                        maxHeight: '70px',
                        overflow: 'auto',
                        marginTop: '20px',
                        marginBottom: tags.length ? '20px' : '0px',
                        overflowX: 'hidden',
                    }}
                >
                    {tags.map((tag) => (
                        <Tooltip title={tag.name.length > 30 ? tag.name : null}>
                            <Chip
                                label={tag.name}
                                onDelete={() => {
                                    setTags(tags.filter((existingTag) => existingTag != tag));
                                    if (isExistingTag(tag.name))
                                        setDeletedTags(deletedTags.concat(tag.name));
                                }}
                                style={{
                                    marginRight: '2px',
                                    marginBottom: '5px',
                                    backgroundColor: tag.color,
                                    maxWidth: '200px',
                                }}
                            />
                        </Tooltip>
                    ))}
                    <div id="displayed-tags-bottom" />
                </div>
                {!Boolean(mode == 'deleting') && (
                    <Flex alignCenter justifyCenter style={{ marginBottom: '20px' }}>
                        <Tooltip title={t('Set tag color')}>
                            <MuiColorInput
                                format="hex"
                                value={tagColor}
                                onChange={setTagColor}
                                style={{ width: '14%', marginRight: '10px' }}
                            />
                        </Tooltip>
                        <Autocomplete
                            filterOptions={filterOptions}
                            disablePortal
                            options={allTags.map((tag) => tag.name) ?? []}
                            sx={{ width: '87%' }}
                            freeSolo={true}
                            value={inputValue}
                            onHighlightChange={(e, option) => {
                                setHighlightedTag(option as string);
                            }}
                            onChange={(e, newValue) => {
                                setCurrTag(newValue ?? '');
                                setHighlightedTag('');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    sx={{
                                        input: { color: isDuplicate ? 'red' : Theme.primaryDark },
                                    }}
                                    onChange={(e) => {
                                        setCurrTag(e.target.value);
                                        setHighlightedTag('');
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            if (
                                                !isEmpty(highlightedTag) &&
                                                highlightedTag !== tag
                                            ) {
                                                setCurrTag(highlightedTag);
                                                setHighlightedTag('');
                                                return;
                                            }
                                            if (!isDuplicate && /\S/.test(tag)) {
                                                setTags(
                                                    tags.concat({ name: tag, color: tagColor })
                                                );
                                                sanitizeDeleted(tag);
                                                setInputValue('');
                                                scrollToDisplayBottom();
                                                setCurrTag('');
                                            }
                                        }
                                    }}
                                    placeholder={t('Enter a new tag')}
                                />
                            )}
                        />
                    </Flex>
                )}
                <Flex justifyEnd>
                    <Button
                        variant="text"
                        style={{ marginRight: '10px' }}
                        onClick={() => cancelAction()}
                    >
                        <MeliorTranslate valueKey="Cancel" />
                    </Button>
                    <Button
                        variant="contained"
                        disabled={!hasDeleted() && !hasAdded()}
                        onClick={() => saveTags()}
                    >
                        <MeliorTranslate valueKey="Save" />
                    </Button>
                </Flex>
            </Box>
        </Modal>
    );
}
