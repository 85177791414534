import { InfoOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { getUserSessionData } from '../../../utils/userSession';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { Theme } from '../../../theme';

export default function CopyAccessTokenButton() {
    const [clickCount, setClickCount] = useState(0);
    const user = getUserSessionData();

    // copy user token after 5 rapid clicks
    const handleClick = () => {
        setClickCount((prevCount) => {
            const currentCount = prevCount + 1;

            if (!isEmpty(user) && currentCount === 5) {
                navigator.clipboard.writeText(user?.accessToken as string);
                toast.success('userToken has been copied to clipboard.', {
                    position: 'top-center',
                });

                return 0;
            }

            return currentCount;
        });
    };

    return (
        <IconButton onClick={handleClick}>
            <InfoOutlined sx={{ fontSize: 20, color: Theme.primaryDark }} />
        </IconButton>
    );
}
