import { useQuery } from '@tanstack/react-query';
import { IDocument } from '../@types/Document';
import { fetchQueries } from '../api/queries.api';

export function useSavedQueries(params, queryKey) {
    const { data, ...rest } = useQuery({
        queryFn: () => fetchQueries(params),
        queryKey: [queryKey],
        refetchOnWindowFocus: false,
    });

    return {
        savedQueries: data?.items || ([] as IDocument[]),
        ...rest,
    };
}
