import AnnouncementBanner from '../../AnnouncementBanner/AnnouncementBanner';
import Container from '../Common/Container';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import { useState } from 'react';
import Flex from 'styled-flex-component';

interface Props {
    children: React.ReactNode;
}

export default function DefaultLayout({ children }: Props) {
    const [isSideBarMaximized, setIsSideBarMaximized] = useState<boolean>(true);

    return (
        <div className="wrapper">
            <Navbar isSideBarMaximized={isSideBarMaximized} />
            <Flex>
                <Sidebar
                    isSideBarMaximized={isSideBarMaximized}
                    setIsSideBarMaximized={setIsSideBarMaximized}
                />
                <Container
                    width={isSideBarMaximized ? 'calc(100vw - 248px);' : '100vw'}
                    leftOuterSpacing={isSideBarMaximized ? '248px' : '80px'}
                    topOuterSpacing={4.5371125}
                    height={'calc(100vh - 72.5938px)'}
                    overflow="auto"
                    data-testid="client-page"
                >
                    <Container
                        topInnerSpacing={1.3}
                        bottomInnerSpacing={1.3}
                        leftInnerSpacing={1.5}
                        rightInnerSpacing={1.5}
                    >
                        <AnnouncementBanner />
                        {children}
                    </Container>
                </Container>
            </Flex>
        </div>
    );
}
