import { Button } from '@mui/material';
import Flex from 'styled-flex-component';
import { Send } from '@mui/icons-material';
import styled from 'styled-components';
import Container from '../../../UI/Common/Container';
import { Text } from '../../../UI/Typography';
import ChatBubble from './ChatBubble';
import { useContext, useEffect, useState } from 'react';
import { askQuestion } from '../../../../api/chat';
import { toast } from 'react-toastify';
import Lottie from 'react-lottie-player';
import typingJson from './Typing.json';
import { Theme } from '../../../../theme';
import { uniqBy } from 'lodash';
import {
    GetInsightsContext,
    GetInsightsContextType,
    IChatSource,
} from '../../../../pages/Client/GetInsights/context/getInsightsContext';

const QUESTIONS = [
    'Does the price have any escalations and how are they calculated?',
    'What are the consequences of terminating this agreement?',
    'Is this a rolling or fixed term contract?',
];
const LABEL = 'Hi, here you can ask your Document questions such as:';

const StyledInput = styled.input`
    border: none;
    width: 100%;
    outline: none;
`;

interface Response {
    answer: string;
    sources?: IChatSource[];
}

const ChatLLM = () => {
    const { documentData, chats, setSelectedSourcePageIndex, setChats } = useContext(
        GetInsightsContext
    ) as GetInsightsContextType;
    const [response, setResponse] = useState<Response | null>(null);
    const [isLoadingResponse, setIsLoadingResponse] = useState<boolean>(false);
    function scrollDown() {
        const list = window.document.getElementById('chat-list');
        if (!list) return;
        list.scrollTop = list.scrollHeight;
    }

    async function pickSampleQuestion(question) {
        setChats([{ isFromMe: true, message: question }]);
        callAskQuestion(question);
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const question = event.target.question.value;

        if (!question) return;
        event.target.reset();

        setChats([...chats, { isFromMe: true, message: question }]);
        callAskQuestion(question);
    }

    async function callAskQuestion(question) {
        setIsLoadingResponse(true);
        try {
            const response = await askQuestion(documentData.id, question);
            setResponse(response);
        } catch (e) {
            toast.error('Something went wrong. Please try again');
        }
    }

    useEffect(() => {
        if (response) {
            setChats([
                ...chats,
                { isFromMe: false, message: response.answer, sources: response.sources },
            ]);
            setIsLoadingResponse(false);
        }
    }, [response]);

    useEffect(() => {
        scrollDown();
    }, [chats]);

    return (
        <Container height="calc(100vh - 121px)">
            <Flex column justifyBetween full>
                {!Boolean(chats.length) && (
                    <Container outerSpacing={0.8125}>
                        <Text>{LABEL}</Text>
                        {QUESTIONS.map((question) => (
                            <Container
                                cursor="pointer"
                                onClick={() => pickSampleQuestion(question)}
                                topOuterSpacing={0.8}
                            >
                                <Container
                                    innerSpacing={0.5}
                                    leftInnerSpacing={1.4}
                                    rightInnerSpacing={1.4}
                                    border={`2px solid ${Theme.primary}`}
                                    borderRadius={20}
                                >
                                    <Text customFontSize={0.8125} color={Theme.primary}>
                                        {question}
                                    </Text>
                                </Container>
                            </Container>
                        ))}
                    </Container>
                )}

                {Boolean(chats.length) && (
                    <Container
                        id="chat-list"
                        innerSpacing={0.8125}
                        overflow="auto"
                        height="calc(100vh - 200px)"
                    >
                        <Flex column>
                            {chats.map((chat) => (
                                <ChatBubble isFromMe={chat.isFromMe}>
                                    <Container>
                                        {chat.message}

                                        {Boolean(chat.sources && chat.sources.length) && (
                                            <Container
                                                topOuterSpacing={0.5}
                                                topInnerSpacing={0.5}
                                                borderTop="1px solid #CBD3DD"
                                                style={{ color: '#74797E', fontStyle: 'italic' }}
                                            >
                                                Reference:&nbsp;&nbsp;
                                                {uniqBy(chat.sources, 'page')
                                                    ?.sort((a, b) => a.page - b.page)
                                                    .map((source, index) => (
                                                        <>
                                                            <span
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    textDecoration: 'underline',
                                                                }}
                                                                onClick={() =>
                                                                    setSelectedSourcePageIndex(
                                                                        source.page - 1
                                                                    )
                                                                }
                                                            >
                                                                page {source.page}
                                                            </span>
                                                            {index !== chat.sources!.length - 1
                                                                ? ', '
                                                                : ''}
                                                        </>
                                                    ))}
                                            </Container>
                                        )}
                                    </Container>
                                </ChatBubble>
                            ))}
                        </Flex>
                        {isLoadingResponse && (
                            <Lottie
                                loop
                                animationData={typingJson}
                                play
                                style={{ width: 50, height: 50 }}
                            />
                        )}
                    </Container>
                )}

                <Container borderTop="1px solid #D9D9D9" innerSpacing={0.5}>
                    <form onSubmit={handleSubmit}>
                        <Flex full alignCenter>
                            <StyledInput
                                type="text"
                                id="question"
                                name="question"
                                disabled={isLoadingResponse}
                                placeholder="Enter your question"
                                autoComplete="off"
                            />
                            <Container leftOuterSpacing={0.3}>
                                <Button type="submit">
                                    <Send htmlColor="#00000080" />
                                </Button>
                            </Container>
                        </Flex>
                    </form>
                </Container>
            </Flex>
        </Container>
    );
};

export default ChatLLM;
