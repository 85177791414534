import { ITagItem } from '../@types/Tag';

export function tagsToArray(tags) {
    if (!tags) return [];

    let tagArray: ITagItem[] = [];
    Object.keys(tags).map((key) => {
        return tagArray.push({
            name: key,
            color: tags[key],
        });
    });
    return tagArray;
}

export function tagArrayToObject(tags) {
    let tagObject = {};
    tags.forEach((tag) => {
        tagObject[tag.name] = tag.color;
    });
    return tagObject;
}

export function adjustColor(color, amount) {
    return (
        '#' +
        color
            .replace(/^#/, '')
            .replace(/../g, (color) =>
                (
                    '0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
                ).substr(-2)
            )
    );
}
