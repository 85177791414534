import {
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    IconButton,
    TableCell,
    TableRow,
    Tooltip,
} from '@mui/material';
import Container from '../../../UI/Common/Container';
import DocumentLink from '../../../UI/DocumentLink';
import Flex from 'styled-flex-component';
import { Text } from '../../../UI/Typography';
import { DeleteOutline, DownloadOutlined, Edit } from '@mui/icons-material';
import { IDocument } from '../../../../@types/Document';
import { DocumentStatus } from '../../../../enums/DocumentStatus';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import DocumentTypeChip from '../../../UI/DocumentTypeChip/DocumentTypeChip';
import DeleteDocumentDialog from './DeleteDocumentDialog';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { adminGetDownloadLink } from '../../../../api/box/admin/file';
import ErrorInfoDropdown from './ErrorInfoDropdown';
import { getUserSessionData } from '../../../../utils/userSession';
import DocumentProcessingProgress from '../../../DocumentProcessingProgress/DocumentProcessingProgress';
import { DocumentUpdate } from '../../../../@types/Websocket';
import { getUserConfig } from '../../../../utils/userConfig';
import { getDocumentInsights } from '../../../../api/files.api';
import { Theme } from '../../../../theme';
import { adjustColor } from '../../../../utils/transformTags';
import { MeliorTranslate } from '../../../MeliorTranslate';

interface Props {
    documents: IDocument[];
    document: IDocument;
    onDeleteSuccess: (docs: IDocument[]) => void;
    onReUploadSuccess: () => void;
    isSelectingAll: boolean;
    selectedDocuments: IDocument[];
    setSelectedDocuments: (docs: IDocument[]) => void;
    hasSelectedAll: boolean;
    setIsAddTagOpen: (isOpen: boolean) => void;
    setTaggingMode: (mode: string) => void;
    setDocumentTags: (tags: Object) => void;
    setTaggingDocIds: (ids: string[]) => void;
    setSelectedTags: (tags: string[]) => void;
    selectedTags: string[];
    setSelectedType: (type: string) => void;
}

export default function DocumentsTableItem(props: Props) {
    const [document, setDocument] = useState(props.document);
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const user = getUserSessionData();
    const userConfig = getUserConfig();

    const navigate = useNavigate();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [hoveredTag, setHoveredTag] = useState<string>('');
    const [hoveredDoc, setHoveredDoc] = useState<string>('');
    const docTags = Object.keys(document.tags);

    useEffect(() => {
        setDocument(props.documents.find((doc) => doc.id == document.id) ?? ({} as IDocument));
    }, [props.documents]);

    useEffect(() => {
        if (document.status !== DocumentStatus.PROCESSING) setIsSelected(props.isSelectingAll);
    }, [props.isSelectingAll]);

    useEffect(() => {
        if (isSelected) {
            if (!props.isSelectingAll || !props.hasSelectedAll) {
                props.setSelectedDocuments(props.selectedDocuments.concat(document));
            }
        } else {
            props.setSelectedDocuments(
                props.selectedDocuments.filter((doc) => doc.id !== props.document.id)
            );
        }
    }, [isSelected]);

    const downloadFileMutation = useMutation(['getDocumentDownloadLink'], (documentId: string) =>
        adminGetDownloadLink(documentId)
    );

    const getInsight = useMutation(['getInsight'], (documentId: string) =>
        getDocumentInsights(documentId)
    );

    const downloadDocument = async () => {
        try {
            const documentLink = await downloadFileMutation.mutateAsync(document.id);
            window.open(documentLink, '_blank');
        } catch {
            toast.error('Unable to download document.');
        }
    };

    const onDocumentReady = async (message) => {
        getInsight.mutateAsync(document.id).then((result) => {
            setDocument((prev) => ({
                ...prev,
                status: DocumentStatus.READY,
                type: result.type,
            }));
        });
    };

    const onDocumentUpdate = (topic: string, message: DocumentUpdate) => {
        // TODO: don't use any type
        if (message?.type) setDocument((prev) => ({ ...prev, type: message.type } as any));
    };

    const addTag = () => {
        props.setIsAddTagOpen(true);
        props.setTaggingMode(docTags.length ? 'updating' : 'adding');
        props.setDocumentTags(document.tags);
        props.setTaggingDocIds([document.id]);
    };

    return (
        <>
            <TableRow key={document.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{ width: '10px' }}>
                    <Checkbox
                        disabled={document.status === DocumentStatus.PROCESSING}
                        checked={isSelected}
                        onClick={() => setIsSelected(!isSelected)}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        style={{
                            color: isHovered || isSelected ? Theme.primary : 'lightgray',
                            padding: 0,
                        }}
                    />
                </TableCell>
                <TableCell>
                    <Container maxWidth={17} wordBreak>
                        <DocumentLink document={document} />
                    </Container>
                </TableCell>
                <TableCell>
                    <DocumentTypeChip
                        label={document.type}
                        setSelectedType={props.setSelectedType}
                    />
                </TableCell>
                <TableCell>
                    {docTags.length ? (
                        <div
                            style={{
                                width: '250px',
                                height: '58px',
                                overflowX: 'hidden',
                                overflowY: 'auto',
                            }}
                            onMouseEnter={() => setHoveredDoc(document.id)}
                            onMouseLeave={() => setHoveredDoc('')}
                        >
                            {Boolean(hoveredDoc == document.id) && (
                                <IconButton
                                    style={{
                                        height: '24px',
                                        width: '24px',
                                        marginRight: '5px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => addTag()}
                                >
                                    <Edit fontSize="small" color="primary" />
                                </IconButton>
                            )}
                            {docTags.map((tag, index) => (
                                <span
                                    onMouseEnter={() =>
                                        setHoveredTag(document.id.concat(index.toString()))
                                    }
                                    onMouseLeave={() => setHoveredTag('')}
                                    onClick={() => {
                                        if (!props.selectedTags.includes(tag))
                                            props.setSelectedTags(props.selectedTags.concat([tag]));
                                    }}
                                >
                                    <Tooltip title={tag.length > 30 ? tag : null}>
                                        <Chip
                                            label={tag}
                                            key={document.id.concat(index.toString())}
                                            size="small"
                                            style={{
                                                marginRight: '5px',
                                                marginTop: '2px',
                                                marginBottom: '2px',
                                                cursor: 'pointer',
                                                maxWidth: '200px',
                                                backgroundColor:
                                                    hoveredTag ==
                                                    document.id.concat(index.toString())
                                                        ? adjustColor(document.tags[tag], 20)
                                                        : document.tags[tag],
                                            }}
                                        />
                                    </Tooltip>
                                </span>
                            ))}
                        </div>
                    ) : (
                        <Flex alignCenter justifyBetween>
                            <Button
                                variant="outlined"
                                disabled={document.status === DocumentStatus.PROCESSING}
                                onClick={() => addTag()}
                            >
                                <Text>
                                    <MeliorTranslate valueKey="Add Tag" />
                                </Text>
                            </Button>
                        </Flex>
                    )}
                </TableCell>
                <TableCell>
                    {document.status !== DocumentStatus.READY && !userConfig?.websocketBaseUrl && (
                        <Flex alignCenter>
                            <CircularProgress size={20} />
                            <Container leftOuterSpacing={0.5}>
                                <Text
                                    customFontSize={0.875}
                                    capitalize
                                    color="rgba(13, 24, 50, 0.54)"
                                >
                                    {document.status}
                                </Text>
                            </Container>
                        </Flex>
                    )}

                    {document.status !== DocumentStatus.READY &&
                        document.status !== DocumentStatus.ERROR &&
                        userConfig?.websocketBaseUrl && (
                            <DocumentProcessingProgress
                                sha1={document.id}
                                tenantId={user?.tenantId}
                                onDocumentReady={onDocumentReady}
                                onMessage={onDocumentUpdate}
                            />
                        )}

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {document.status !== DocumentStatus.PROCESSING &&
                            document.status !== DocumentStatus.QUEUED && (
                                <Text capitalize>{document.status}</Text>
                            )}

                        {document.status === DocumentStatus.ERROR && (
                            <ErrorInfoDropdown
                                document={document}
                                onReUploadSuccess={props?.onReUploadSuccess}
                            />
                        )}
                    </div>
                </TableCell>
                <TableCell>
                    <Flex alignCenter justifyBetween>
                        <Button
                            variant="outlined"
                            disabled={document.status === DocumentStatus.PROCESSING}
                            onClick={() => navigate(`/client/insights/${document.id}`)}
                        >
                            <Text>
                                <MeliorTranslate valueKey="Get Insights" />
                            </Text>
                        </Button>

                        <IconButton
                            disabled={document.status === DocumentStatus.PROCESSING}
                            onClick={() => downloadDocument()}
                            color="inherit"
                        >
                            {downloadFileMutation.isLoading ? (
                                <CircularProgress size={20} />
                            ) : (
                                <DownloadOutlined />
                            )}
                        </IconButton>

                        <IconButton
                            color="inherit"
                            disabled={document.status === DocumentStatus.PROCESSING}
                            onClick={() => setShowDeleteDialog(true)}
                        >
                            <DeleteOutline />
                        </IconButton>
                    </Flex>
                </TableCell>
            </TableRow>

            <DeleteDocumentDialog
                open={showDeleteDialog}
                documents={[props.document]}
                onClose={() => {
                    setShowDeleteDialog(false);
                }}
                onDeleteSuccess={() => {
                    setShowDeleteDialog(false);
                    props?.onDeleteSuccess([document]);
                }}
            />
        </>
    );
}
