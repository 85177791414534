import Box from '@mui/material/Box';
import Flex from 'styled-flex-component';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { StyledSearchTextField } from '../../AdvancedSearch/SearchSectionInput/StyledTextField';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { isEmpty } from 'lodash';

interface IDocumentsTypeFilterSelectProps {
    setTags: (tags: string[]) => void;
}

const WorkflowsTagSelector = ({ setTags }: IDocumentsTypeFilterSelectProps) => {
    const { t } = useTranslation();
    const [currTags, setCurrTags] = useState<string[]>();
    return (
        <Box sx={{ minWidth: 600 }}>
            <Flex alignCenter>
                <Autocomplete
                    multiple
                    id="tags-filled"
                    options={[]}
                    sx={{ minWidth: 600, maxWidth: '50%' }}
                    freeSolo
                    onChange={(e, value) => {
                        setTags(value);
                        setCurrTags(value);
                    }}
                    renderTags={(value: readonly string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) => (
                        <Flex
                            alignCenter
                            style={{
                                background: 'rgba(0, 0, 0, 0.06)',
                                borderRadius: '3px',
                                paddingLeft: '14px',
                                width: '602px',
                            }}
                        >
                            <Flex
                                alignCenter
                                style={{
                                    height: isEmpty(currTags) ? '37px' : '50px',
                                    width: '20px',
                                }}
                            >
                                <SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                            </Flex>
                            <StyledSearchTextField
                                {...params}
                                size="small"
                                autoComplete="off"
                                placeholder={t('Enter a tag')}
                            />
                        </Flex>
                    )}
                />
            </Flex>
        </Box>
    );
};

export default WorkflowsTagSelector;
