import { Theme } from '../../../theme';
import Container from '../../UI/Common/Container';
import Flex from 'styled-flex-component';
import { Text } from '../../UI/Typography';
import { ChevronLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import DocumentsTypeSelector from '../../UI/DocumentTypeSelector.tsx/DocumentTypeSelector';
import { useContext, useState } from 'react';
import { IFeedback } from '../../../@types/Feedback';
import { provideFeedback } from '../../../api/feedback';
import { SxProps } from '@mui/material';
import {
    GetInsightsContext,
    GetInsightsContextType,
} from '../../../pages/Client/GetInsights/context/getInsightsContext';
import ClauseNavigationBar from '../ClauseNavigationBar';
import KeyDataNavigationBar from '../KeyDataNavigationBar';

interface IGetInsightsHeaderProps {
    setNewDocType: (docType: string) => void;
}

const GetInsightsHeader = ({ setNewDocType }: IGetInsightsHeaderProps) => {
    const { documentData } = useContext(GetInsightsContext) as GetInsightsContextType;
    const [selectedDocumentType, setSelectDocumentType] = useState<string>(documentData.type);
    const navigate = useNavigate();

    const onDocTypeChange = (selectedType: string) => {
        setSelectDocumentType(selectedType);
        setNewDocType(selectedType);
        const feedback: IFeedback[] = [
            {
                correct: false,
                key: 'type',
                idx: 0,
                field: 'type',
                gt: selectedType,
            },
        ];
        provideFeedback(documentData.id, feedback);
    };
    return (
        <Container fixed width="100%" zIndex={1} backgroundColor={Theme.primaryDark}>
            <Container
                leftInnerSpacing={2}
                rightInnerSpacing={2.6875}
                topInnerSpacing={0.75}
                bottomInnerSpacing={0.75}
                color={Theme.whiteColor}
            >
                <Flex justifyBetween alignCenter>
                    <Flex alignCenter>
                        <Container
                            rightOuterSpacing={0.75}
                            topOuterSpacing={0.4}
                            cursor="pointer"
                            onClick={() => {
                                window.history?.length > 1
                                    ? navigate(-1)
                                    : navigate('/client/documents');
                                localStorage.setItem('currentMatchIndex', '0');
                            }}
                        >
                            <ChevronLeft style={{ color: Theme.whiteColor }} />
                        </Container>
                        <Container maxWidth={30}>
                            <Text
                                customFontWeight={600}
                                data-testid="header-document-name"
                                customFontSize={1}
                                color={Theme.whiteColor}
                                whiteSpace="break-spaces"
                                wordBreak="break-all"
                            >
                                {documentData.name}
                            </Text>
                        </Container>
                        <Container leftOuterSpacing={1}>
                            <DocumentsTypeSelector
                                selectedType={selectedDocumentType}
                                setSelectedType={onDocTypeChange}
                                customStyles={
                                    {
                                        'background': 'rgba(255, 255, 255, 0.08)',
                                        'paddingLeft': '10px',
                                        'paddingRight': '10px',
                                        'paddingTop': '7px',
                                        'paddingBottom': '7px',
                                        'borderRadius': '100px',
                                        'fontSize': '13px',
                                        'color': Theme.whiteColor,
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: Theme.whiteColor,
                                        },
                                        '& .MuiSvgIcon-root': {
                                            color: Theme.whiteColor,
                                        },
                                    } as SxProps
                                }
                            />
                        </Container>
                    </Flex>
                    <Container leftOuterSpacing={0.5}>
                        <ClauseNavigationBar />
                        <KeyDataNavigationBar />
                    </Container>
                </Flex>
            </Container>
        </Container>
    );
};
export default GetInsightsHeader;
