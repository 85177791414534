import { QueryBuilderMaterial } from '@react-querybuilder/material';
import { useState } from 'react';
import { QueryBuilder } from 'react-querybuilder';
import Container from '../UI/Common/Container';
import { Text } from '../UI/Typography';
import Flex from 'styled-flex-component';
import { Button } from '@mui/material';

interface IQueryBuilderRule {
    field: string;
    operator: string;
    value: string;
}

interface IQueryBuilderBuilderRuleGroup {
    rules: IQueryBuilderRule;
    combinator: string;
}

const contractTypes = [
    { name: 'Affiliate Agreement', label: 'Affiliate Agreement' },
    { name: 'Agency Agreement', label: 'Agency Agreement' },
    {
        name: 'Collaboration/Cooperation Agreement',
        label: 'Collaboration/Cooperation Agreement',
    },
    { name: 'Co-Branding Agreement', label: 'Co-Branding Agreement' },
    { name: 'Consulting Agreement', label: 'Consulting Agreement' },
    { name: 'Development Agreement', label: 'Development Agreement' },
    { name: 'Distributor Agreement', label: 'Distributor Agreement' },
    { name: 'Endorsement Agreement', label: 'Endorsement Agreement' },
    { name: 'Franchise Agreement', label: 'Franchise Agreement' },
    { name: 'Hosting Agreement', label: 'Hosting Agreement' },
    { name: 'IP Agreement', label: 'IP Agreement' },
    { name: 'Joint Venture Agreement', label: 'Joint Venture Agreement' },
    { name: 'License Agreement', label: 'License Agreement' },
    { name: 'Maintenance Agreement', label: 'Maintenance Agreement' },
    { name: 'Manufacturing Agreement', label: 'Manufacturing Agreement' },
    { name: 'Marketing Agreement', label: 'Marketing Agreement' },
    { name: 'Memorandum of Understanding', label: 'Memorandum of Understanding' },
    {
        name: 'Non-Compete/No-Solicit/Non-Disparagement Agreement',
        label: 'Non-Compete/No-Solicit/Non-Disparagement Agreement',
    },
    { name: 'Non Disclosure Agreement', label: 'Non Disclosure Agreement' },
    { name: 'Outsourcing Agreement', label: 'Outsourcing Agreement' },
    { name: 'Purchase Order', label: 'Purchase Order' },
    { name: 'Promotion Agreement', label: 'Promotion Agreement' },
    { name: 'Reseller Agreement', label: 'Reseller Agreement' },
    { name: 'Service Agreement', label: 'Service Agreement' },
    { name: 'Sponsorship Agreement', label: 'Sponsorship Agreement' },
    { name: 'Supply Agreement', label: 'Supply Agreement' },
    {
        name: 'Strategic Alliance Agreement',
        label: 'Strategic Alliance Agreement',
    },
    { name: 'Transportation Agreement', label: 'Transportation Agreement' },
];

const fields = [
    { name: 'party', label: 'Party', operators: [{ name: '=', label: 'is' }] },
    {
        name: 'contractType',
        label: 'Contract Type',
        valueEditorType: 'select',
        inputType: 'select',
        values: contractTypes,
        operators: [{ name: '=', label: 'is' }],
    },
    {
        name: 'agreementDate',
        label: 'Agreement Date',
        inputType: 'date',
        operators: [
            { name: '=', label: 'is' },
            { name: '>', label: 'after' },
            { name: '<', label: 'before' },
        ],
    },
    {
        name: 'effectiveDate',
        label: 'Effective Date',
        inputType: 'date',
        operators: [
            { name: '=', label: 'is' },
            { name: '>', label: 'after' },
            { name: '<', label: 'before' },
        ],
    },
    {
        name: 'terminationDate',
        label: 'Termination Clause: Date of Termination',
        inputType: 'date',
        operators: [
            { name: '=', label: 'is' },
            { name: '>', label: 'after' },
            { name: '<', label: 'before' },
        ],
    },
];

const initialQuery = {
    combinator: 'and',
    rules: [],
};

const MeliorQueryBuilder = () => {
    const [query, setQuery] = useState(initialQuery);
    return (
        <Container
            backgroundColor="#CCDBF1"
            topInnerSpacing={1.4375}
            bottomInnerSpacing={0.75}
            leftInnerSpacing={0.75}
            rightInnerSpacing={0.75}
        >
            <Text customFontWeight={500} customFontFamily="Roboto">
                QUERY
            </Text>
            <Container topOuterSpacing={1.05}>
                <QueryBuilderMaterial>
                    <QueryBuilder
                        fields={fields as any}
                        query={query}
                        onQueryChange={(q) => setQuery(q)}
                        addRuleToNewGroups
                        resetOnOperatorChange
                    />
                </QueryBuilderMaterial>
            </Container>
        </Container>
    );
};

export default MeliorQueryBuilder;
