import { Avatar, IconButton } from '@mui/material';
import { Theme } from '../../../../theme';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useState } from 'react';
import { ContextHolder } from '@frontegg/rest-api';
import { AdminPortal } from '@frontegg/react';
import { getUserSessionData, removeUserSessionData } from '../../../../utils/userSession';
import isFronteggUserAdmin from '../../../../utils/isFronteggUserAdmin';
import { removeUserConfig } from '../../../../utils/userConfig';
import { MeliorTranslate } from '../../../MeliorTranslate';

const menuId = 'account-menu';

const ProfileAvatar = () => {
    const user = getUserSessionData();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const logout = () => {
        const baseUrl = ContextHolder.getContext().baseUrl;
        window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
        removeUserSessionData();
        removeUserConfig();
    };

    const showAdminPortal = () => {
        AdminPortal.show();
        handleClose();
    };

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            id={menuId}
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    'overflow': 'visible',
                    'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    'mt': 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem onClick={handleClose}>
                <b>{user?.name}</b>
            </MenuItem>
            <MenuItem onClick={showAdminPortal}>
                <MeliorTranslate valueKey="Settings" />
            </MenuItem>
            {isFronteggUserAdmin(user) && (
                <MenuItem
                    onClick={() => {
                        window.open(
                            process.env.REACT_APP_ADMIN_CONSOLE_BASE_URL +
                                `/transfer?token=${user?.accessToken}`,
                            '_blank'
                        );
                        handleClose();
                    }}
                >
                    Admin Console
                </MenuItem>
            )}
            <MenuItem onClick={() => window.open('https://melior.substack.com/', '_blank')}>
                <MeliorTranslate valueKey="Help" />
            </MenuItem>
            <MenuItem onClick={logout}>
                <MeliorTranslate valueKey="Log Out" />
            </MenuItem>
        </Menu>
    );

    return (
        <>
            <IconButton style={{ padding: 1.3 }} onClick={handleClick}>
                {user?.profilePictureUrl && <Avatar src={user?.profilePictureUrl} />}
                {!user?.profilePictureUrl && (
                    <Avatar sx={{ bgcolor: Theme.primary, width: 32, height: 32, padding: '4px' }}>
                        {user?.name.charAt(0)}
                    </Avatar>
                )}
            </IconButton>
            {renderMenu}
        </>
    );
};

export default ProfileAvatar;
