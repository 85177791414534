import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Theme } from '../../../theme';

interface Props {
    isSideBarMaximized: boolean;
    onClick: () => void;
    style?: React.CSSProperties;
}

export default function ChevronToggleButton(props: Props) {
    return (
        <Button
            color="info"
            variant="contained"
            disabled={false}
            onClick={props?.onClick}
            style={{
                ...props?.style,
                borderRadius: '50%',
                minWidth: '25px',
                minHeight: '25px',
                height: '25px',
                width: '25px',
                backgroundColor: Theme.whiteColor,
                color: Theme.primaryDark,
                boxShadow: '5px 5px 5px 1px rgba(63, 63, 68, 0.5)',
                filter: 'drop-shadow(0px 1px 3px rgba(63, 63, 68, 0.15))',
            }}
            size="small"
        >
            <div
                style={{
                    marginTop: '6px',
                    marginLeft: '-1px',
                }}
            >
                {props.isSideBarMaximized && <ChevronLeft />}
                {!props.isSideBarMaximized && <ChevronRight />}
            </div>
        </Button>
    );
}
