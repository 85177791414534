import { IComparisonDetail } from '../@types/Comparison';
import apiClient from './apiClient';

export const fetchComparisons = async () => {
    return apiClient.get(`/clause/comparisons`).then((res) => res.data);
};

export const createComparisonTask = async (params: URLSearchParams) => {
    return apiClient.put(`/clause/comparisons`, null, { params }).then((res) => res.data);
};

export const deleteComparisonTask = async (taskId: string) => {
    return apiClient.delete(`/clause/comparisons/${taskId}`).then((res) => res.data);
};

export const getComparison = async (comparisonId) => {
    return apiClient
        .get(`/clause/comparisons/${comparisonId}`)
        .then((res) => res.data as IComparisonDetail)
        .catch((err) => Promise.reject({ ...err }));
};

export const getComparisonExportFile = async (comparisonId: string) => {
    return apiClient
        .get(`/clause/comparisons/${comparisonId}?export_format=xlsx`, {
            responseType: 'arraybuffer',
        })
        .then((res) => {
            return new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
        })
        .catch((err) => Promise.reject({ ...err }));
};
