import { Theme } from '../../../theme';
import Container from '../../UI/Common/Container';
import { H6 } from '../../UI/Typography';
import Flex from 'styled-flex-component';
import { AddRounded, CloseOutlined } from '@mui/icons-material';
import { IconButton, List, ListItem, ListItemText } from '@mui/material';

import { IAdvancedSearchPageSearchFilter } from '../../../pages/Client/AdvancedSearch/Page';
import SearchAgreementTypeFilterField from './SearchAgreementTypeFilterField';
import SearchTextFilterField from './SearchTextFilterField';
import SearchDateFilterField from './SearchDateFilterField';
import { useContext } from 'react';
import {
    AdvancedSearchContext,
    AdvancedSearchContextType,
} from '../../../pages/Client/AdvancedSearch/context/advancedSearchContext';

interface ISearchFiltersProps {
    isFiltersOverlayOpened: boolean;
    setFiltersAdded: (clauses: IAdvancedSearchPageSearchFilter[]) => void;
    setIsFiltersOverlayOpened: (value: boolean) => void;
    setIsAddFilterModalOpen: (val: boolean) => void;
}

const SearchFiltersOverlayList = ({
    isFiltersOverlayOpened,
    setFiltersAdded,
    setIsFiltersOverlayOpened,
    setIsAddFilterModalOpen,
}: ISearchFiltersProps) => {
    if (!isFiltersOverlayOpened) return <></>;

    const { filtersAdded, selectedClauseIndex, setSelectedClauseIndex } = useContext(
        AdvancedSearchContext
    ) as AdvancedSearchContextType;

    const onFilterRemove = (index: number) => {
        const temp = [...filtersAdded];
        temp.splice(index, 1);
        setFiltersAdded(temp);
    };

    return (
        <Container
            fixed
            rightPosition={2}
            width={21.875}
            topPosition={6.5}
            zIndex={9}
            backgroundColor={Theme.whiteColor}
            topInnerSpacing={2.0625}
            bottomInnerSpacing={2.0625}
            leftInnerSpacing={1.6875}
            rightInnerSpacing={1.6875}
            height="calc(100vh - 30px)"
            boxShadow="0px 2px 4px -1px rgba(0, 0, 0, 0.2);"
            filter="drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12));"
        >
            <Container bottomOuterSpacing={2.625}>
                <Flex justifyBetween alignCenter>
                    <Flex center>
                        <H6>Filters</H6>
                        <Container leftOuterSpacing={0.8}>
                            <IconButton
                                style={{ backgroundColor: '#10B981', color: 'white' }}
                                color="success"
                                onClick={() => setIsAddFilterModalOpen(true)}
                            >
                                <AddRounded />
                            </IconButton>
                        </Container>
                    </Flex>
                    <Container
                        cursor="pointer"
                        onClick={() => {
                            setSelectedClauseIndex(null);
                            setIsFiltersOverlayOpened(false);
                        }}
                    >
                        <CloseOutlined sx={{ color: 'rgba(13, 24, 50, 0.54)' }} />
                    </Container>
                </Flex>
            </Container>

            <List sx={{ maxHeight: 'calc(100vh - 187px)', overflowY: 'auto' }}>
                {filtersAdded.map((clause: IAdvancedSearchPageSearchFilter, index: number) => (
                    <Container
                        cursor="pointer"
                        onClick={() => setSelectedClauseIndex(index)}
                        borderBottom={
                            selectedClauseIndex === index
                                ? 'none'
                                : '1px solid rgba(13, 24, 50, 0.12)'
                        }
                        borderLeft={selectedClauseIndex === index ? '4px solid #10B981' : ''}
                    >
                        <ListItem
                            style={{
                                width: '100%',
                            }}
                        >
                            <Flex alignCenter justifyBetween full>
                                <ListItemText primary={clause.filterLabel} />
                                {selectedClauseIndex === index && (
                                    <CloseOutlined
                                        sx={{ color: 'black', opacity: 0.26 }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onFilterRemove(index);
                                        }}
                                    />
                                )}
                            </Flex>
                        </ListItem>
                        {selectedClauseIndex === index && clause.filterLabel.includes('Date') && (
                            <SearchDateFilterField
                                clause={clause}
                                filtersAdded={filtersAdded}
                                setFiltersAdded={setFiltersAdded}
                            />
                        )}
                        {selectedClauseIndex === index &&
                            !clause.filterLabel.includes('Date') &&
                            clause.filterLabel !== 'Type' && (
                                <SearchTextFilterField
                                    clause={clause}
                                    filtersAdded={filtersAdded}
                                    setFiltersAdded={setFiltersAdded}
                                />
                            )}
                        {selectedClauseIndex === index && clause.filterLabel === 'Type' && (
                            <SearchAgreementTypeFilterField
                                clause={clause}
                                selectedClauseIndex={selectedClauseIndex}
                                filtersAdded={filtersAdded}
                                setFiltersAdded={setFiltersAdded}
                            />
                        )}
                    </Container>
                ))}
            </List>
        </Container>
    );
};

export default SearchFiltersOverlayList;
