import { Box, LinearProgress, Typography } from '@mui/material';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { getUserSessionData } from '../../utils/userSession';

const InitializePage = () => {
    const user = getUserSessionData();
    const accessToken = user?.accessToken;
    const boxUserId = user?.metadata && JSON.parse(user?.metadata)?.boxUserId;

    const redirectToDashboard = () => {
        window.location.href = `/client/dashboard`;
    };

    useEffect(() => {
        if (isEmpty(boxUserId)) {
            throw new Error('Box User ID is missing');
        } else {
            redirectToDashboard();
        }
    }, [accessToken, boxUserId]);

    return (
        <div
            style={{
                alignContent: 'center',
                display: 'flex',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <Box
                style={{
                    width: '50%',
                    padding: '2em',
                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
                    borderRadius: '10px',
                    margin: 'auto',
                }}
            >
                <Typography
                    variant="h5"
                    align="center"
                    style={{
                        // border: '1px solid red',
                        textAlign: 'left',
                        marginBottom: '.3em',
                    }}
                >
                    Initializing your account...
                </Typography>
                <LinearProgress />
            </Box>
        </div>
    );
};

export default InitializePage;
