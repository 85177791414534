import { Badge, Chip, Divider, FormControl, InputAdornment } from '@mui/material';
import { IAdvancedSearchPageSearchFilter, QType } from '../../../pages/Client/AdvancedSearch/Page';
import { StyledDropdownResults } from './StyledDropdownResults';
import Container from '../../UI/Common/Container';
import { Text } from '../../UI/Typography';
import Flex from 'styled-flex-component';
import { IDocument } from '../../../@types/Document';
import { Theme } from '../../../theme';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import {
    AdvancedSearchContext,
    AdvancedSearchContextType,
} from '../../../pages/Client/AdvancedSearch/context/advancedSearchContext';
import SearchBox from '../../UI/Navbar/SearchBox';
import { useTranslation } from 'react-i18next';

interface ISearchSectionInputProps {
    matchingDocuments: IDocument[];
    matchingType: QType | null;
    setFiltersAdded: (clauses: IAdvancedSearchPageSearchFilter[]) => void;
    setSearchText: (val: string) => void;
}

const SearchSectionInput = ({
    matchingDocuments,
    matchingType,
    setFiltersAdded,
    setSearchText,
}: ISearchSectionInputProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { searchText, filtersAdded, matchingClauses } = useContext(
        AdvancedSearchContext
    ) as AdvancedSearchContextType;
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    const hasMatchingType = Boolean(matchingType);
    const hasMatchingDocuments = Boolean(matchingDocuments.length);
    const hasMatchingClauses = Boolean(matchingClauses.length);

    useEffect(() => {
        if (!searchText) {
            setIsDropdownOpen(false);
            return;
        }
        if (!(hasMatchingClauses || hasMatchingDocuments)) {
            setIsDropdownOpen(false);
            return;
        }

        setIsDropdownOpen(true);
    }, [searchText, hasMatchingClauses, hasMatchingDocuments]);

    function onInputFocus() {
        if (!searchText) {
            return;
        }

        if (!(hasMatchingClauses || hasMatchingDocuments)) {
            return;
        }

        setIsDropdownOpen(true);
    }

    function onClausePick(clause: string) {
        const clauseFound = filtersAdded.find((filter) => filter.filterLabel === clause);
        if (!clauseFound) {
            setSearchText('');

            if (clause.includes('Date')) {
                setFiltersAdded([
                    ...filtersAdded,
                    {
                        filterLabel: clause,
                        value: matchingType?.value,
                        display: `is before ${searchText}`,
                    },
                ]);
                return;
            }

            setFiltersAdded([
                ...filtersAdded,
                { filterLabel: clause, value: searchText, display: `has ${searchText}` },
            ]);
            return;
        }
        setIsDropdownOpen(false);
    }

    return (
        <Container width="616px">
            <FormControl fullWidth sx={{ borderRadius: '4px' }} variant="standard">
                <SearchBox
                    placeholder={t('Search your contracts')}
                    onChange={(searchKey) => setSearchText(searchKey)}
                />
                {isDropdownOpen && (
                    <StyledDropdownResults>
                        {hasMatchingType && (
                            <Container outerSpacing={1.1875}>
                                {_.startCase(matchingType?.type ?? '')}: {searchText}
                            </Container>
                        )}
                        {hasMatchingClauses && (
                            <>
                                <Divider />
                                <Container outerSpacing={1.1875} maxHeight="120px" overflow="auto">
                                    <Text
                                        color={Theme.primary}
                                        customFontSize={0.75}
                                        customFontWeight={500}
                                        uppercase
                                    >
                                        Matching Clauses:
                                    </Text>
                                    <Container topOuterSpacing={0.5}>
                                        <Flex justifyEvenly wrap row>
                                            {matchingClauses.map((clause, index) => (
                                                <Container
                                                    rightOuterSpacing={0.5625}
                                                    topOuterSpacing={0.25}
                                                    bottomOuterSpacing={0.25}
                                                >
                                                    <Chip
                                                        label={clause}
                                                        onClick={(e) => onClausePick(clause)}
                                                        style={{
                                                            backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                                            color: 'rgba(13, 24, 50, 0.87)',
                                                        }}
                                                    />
                                                </Container>
                                            ))}
                                        </Flex>
                                    </Container>
                                </Container>
                            </>
                        )}
                        {hasMatchingDocuments && (
                            <>
                                <Divider />
                                <Container outerSpacing={1.1875}>
                                    <Text
                                        color={Theme.primary}
                                        uppercase
                                        customFontSize={0.75}
                                        customFontWeight={500}
                                    >
                                        Matching Documents:
                                    </Text>
                                    {matchingDocuments
                                        .map((document) => (
                                            <Container
                                                bottomOuterSpacing={1.06}
                                                cursor="pointer"
                                                onClick={() =>
                                                    navigate(`/client/insights/${document.id}`)
                                                }
                                            >
                                                <p
                                                    style={{ fontSize: '13px' }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: document.name,
                                                    }}
                                                />
                                            </Container>
                                        ))
                                        .slice(0, 4)}
                                    <Container
                                        cursor="pointer"
                                        onClick={() => setIsDropdownOpen(false)}
                                    >
                                        <Flex alignCenter>
                                            <Text color={Theme.primary} uppercase>
                                                See All
                                            </Text>
                                            <Container leftOuterSpacing={1}>
                                                <Badge
                                                    badgeContent={matchingDocuments.length}
                                                    color="primary"
                                                />
                                            </Container>
                                        </Flex>
                                    </Container>
                                </Container>
                            </>
                        )}
                    </StyledDropdownResults>
                )}
            </FormControl>
        </Container>
    );
};

export default SearchSectionInput;
