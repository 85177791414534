import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, TableContainer, Tooltip } from '@mui/material';
import ComparisonResultItem from '../Item';
import { Text } from '../../UI/Typography';
import { IComparisonResult } from '../../../@types/Comparison';
import { Info, InfoOutlined } from '@mui/icons-material';

interface IComparisonTableProps {
    comparisonResults: IComparisonResult;
    isHighlighted: boolean;
}

const ComparisonResultTable = ({ comparisonResults, isHighlighted }: IComparisonTableProps) => {
    return (
        <TableContainer>
            <Table id="comparisonTable">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '20%' }}>
                            <Text customFontWeight={700}>Clause Name</Text>
                        </TableCell>
                        <TableCell style={{ width: '30%' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Text customFontWeight={700}>Approved Definition</Text>
                                <Tooltip
                                    title={`Removed words from the master clause definition are highlighted in red. 
                                    ${
                                        !isHighlighted
                                            ? `To show highlights, toggle the "Highlight Clause Difference" button.`
                                            : ''
                                    }`}
                                    sx={{ marginLeft: '5px' }}
                                >
                                    <InfoOutlined fontSize="small" />
                                </Tooltip>
                            </Box>
                        </TableCell>
                        <TableCell style={{ width: '30%' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Text customFontWeight={700}>Extracted Clause</Text>
                                <Tooltip
                                    title={`New words in the extracted clause are highlighted in green. 
                                    ${
                                        !isHighlighted
                                            ? `To show highlights, toggle the "Highlight Clause Difference" button.`
                                            : ''
                                    }`}
                                    sx={{ marginLeft: '5px' }}
                                >
                                    <InfoOutlined fontSize="small" />
                                </Tooltip>
                            </Box>
                        </TableCell>
                        <TableCell style={{ width: '8%' }}>
                            <Text customFontWeight={700}>Semantic Comparison</Text>
                        </TableCell>
                        <TableCell style={{ width: '12%' }}>
                            <Text customFontWeight={700}>Entailment</Text>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {comparisonResults.results && (
                        <>
                            {comparisonResults.results.map((results, index) => (
                                <ComparisonResultItem
                                    key={index}
                                    result={results}
                                    docId={comparisonResults.doc_id}
                                    isHighlighted={isHighlighted}
                                />
                            ))}
                        </>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ComparisonResultTable;
