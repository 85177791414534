import { useEffect, useState } from 'react';
import { fetchBEVersion } from '../../../api/config.api';
import { LINT_VERSION } from '../../../constants';
import getBackendVersion from '../../../utils/getBackendVersion';
import Container from '../Common/Container';
import { useNavigate } from 'react-router-dom';

export default function VersionNumber() {
    const [backendVersion, setBackendVersion] = useState<string>('');
    const navigate = useNavigate();
    const [clickCount, setClickCount] = useState(0);

    const getConfig = async () => {
        try {
            const response = await fetchBEVersion();
            setBackendVersion(response);
        } catch (e) {}
    };

    useEffect(() => {
        getConfig();
    }, []);

    const handleClick = () => {
        setClickCount((prevCount) => {
            const currentCount = prevCount + 1;

            if (currentCount === 5) {
                navigate('/version', {
                    state: {
                        feVersion: LINT_VERSION,
                        beVersion: backendVersion,
                    },
                });
                return 0;
            }

            return currentCount;
        });
    };

    return (
        <Container
            style={{
                position: 'absolute',
                bottom: 0,
                color: 'rgba(255,255,255, 0.5)',
                padding: '1em',
                fontSize: '0.8rem',
            }}
            onClick={handleClick}
        >
            FE: {LINT_VERSION} / BE: {backendVersion}
        </Container>
    );
}
