import axios from 'axios';
import { getUserSessionData } from '../../utils/userSession';
import { isEmpty } from 'lodash';

const adminAPIClient = axios.create();

function interceptor(config: any) {
    const baseUrl = process.env.REACT_APP_ADMIN_API_BASE_URL;

    if (isEmpty(baseUrl)) throw new Error('REACT_APP_ADMIN_API_BASE_URL is not defined');

    const user = getUserSessionData();
    config.baseURL = baseUrl;
    config.headers.Authorization = `Bearer ${user?.accessToken}`;
    return config;
}

adminAPIClient.interceptors.request.use(interceptor);

export default adminAPIClient;
