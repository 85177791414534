import Flex from 'styled-flex-component';
import { Text } from '../Typography';
import {
    Badge,
    Box,
    Checkbox,
    Chip,
    CircularProgress,
    IconButton,
    MenuItem,
    Select,
    Tooltip,
} from '@mui/material';
import { ITagItem } from '../../../@types/Tag';
import { Clear } from '@mui/icons-material';
import { Theme } from '../../../theme';
import { MeliorTranslate } from '../../MeliorTranslate';

interface ITagFilterSelectProps {
    allTags: ITagItem[];
    selectedTags: string[];
    setSelectedTags: (tags: string[]) => void;
    showLabel: boolean;
    height: string;
    loadingTags: boolean;
    showBalloons: boolean;
}

const TagSelector = ({
    allTags,
    selectedTags,
    setSelectedTags,
    showLabel,
    height,
    loadingTags,
    showBalloons,
}: ITagFilterSelectProps) => {
    const placeholder = () => {
        return (
            <Text
                style={{
                    color: showLabel ? 'black' : Theme.primary,
                    fontSize: showLabel ? '16px' : '14px',
                    marginLeft: showLabel ? '0px' : '5px',
                }}
                uppercase={!showLabel}
            >
                <MeliorTranslate valueKey={showLabel ? 'All' : 'Filter by Tag'} />
            </Text>
        );
    };
    return (
        <Flex>
            {showLabel && (
                <Text color="rgba(0, 0, 0, 0.6);" style={{ margin: '10px' }}>
                    <MeliorTranslate valueKey="Tag" />:
                </Text>
            )}
            <Select
                sx={
                    showBalloons
                        ? {
                              height: { height },
                              width: '250px',
                          }
                        : {
                              'height': { height },
                              'width': '250px',
                              '& .MuiOutlinedInput-notchedOutline': {
                                  borderColor: '#93c4ff',
                              },
                              '& .MuiSvgIcon-root': {
                                  color: '#93c4ff',
                              },
                              '&:hover': {
                                  '& .MuiOutlinedInput-notchedOutline': {
                                      borderColor: Theme.primary,
                                  },
                                  '& .MuiSvgIcon-root': {
                                      color: Theme.primary,
                                  },
                              },
                          }
                }
                multiple
                value={selectedTags}
                MenuProps={{ PaperProps: { sx: { maxHeight: '50%' } } }}
                displayEmpty={true}
                renderValue={(selectedTags) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {Boolean(selectedTags.length) ? (
                            <Flex justifyBetween alignCenter>
                                <div style={{ width: '200px', overflow: 'hidden' }}>
                                    <Badge
                                        badgeContent={selectedTags.length}
                                        sx={{ margin: 1.3, marginRight: 2 }}
                                        color="primary"
                                    />
                                    {showBalloons ? (
                                        selectedTags.map((tag) => (
                                            <Chip
                                                label={tag}
                                                style={{
                                                    backgroundColor: allTags.find(
                                                        (x) => x.name == tag
                                                    )?.color,
                                                    maxWidth: '55px',
                                                    overflow: 'ellipsis',
                                                }}
                                            />
                                        ))
                                    ) : (
                                        <>{placeholder()}</>
                                    )}
                                </div>
                                <IconButton
                                    style={{
                                        position: 'absolute',
                                        right: 5,
                                        background: 'white',
                                        zIndex: 100,
                                        height: '20px',
                                        width: '30px',
                                    }}
                                    onMouseDown={() => {
                                        setSelectedTags([]);
                                    }}
                                >
                                    <Clear />
                                </IconButton>
                            </Flex>
                        ) : (
                            <>{placeholder()}</>
                        )}
                    </Box>
                )}
                onChange={(e) => {
                    setSelectedTags(e.target.value as string[]);
                }}
            >
                {loadingTags && (
                    <Flex justifyCenter alignCenter style={{ height: '200px' }}>
                        <CircularProgress
                            style={{
                                color: '#3f51b5',
                                width: '30px',
                                height: '30px',
                            }}
                        />
                    </Flex>
                )}
                {allTags.map((tag) => (
                    <MenuItem key={tag.name} value={tag.name}>
                        <Checkbox checked={selectedTags.includes(tag.name)} />
                        <Tooltip title={tag.name.length > 30 ? tag.name : null}>
                            <Chip
                                label={tag.name}
                                style={{
                                    backgroundColor: tag.color,
                                    maxWidth: '180px',
                                    overflow: 'ellipsis',
                                }}
                            />
                        </Tooltip>
                    </MenuItem>
                ))}
            </Select>
        </Flex>
    );
};

export default TagSelector;
