import CardContainer from '../../../components/UI/Common/Card/Card';
import Container from '../../../components/UI/Common/Container';
import DocumentsTable from '../../../components/Documents/Tables/Documents';
import { TabPanel } from '../../../components/UI/Common/Tabs/Tabs';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Badge, Box, Button, Chip, IconButton, Tab, Tabs, Tooltip } from '@mui/material';
import Flex from 'styled-flex-component';
import { useDocuments } from '../../../hooks/useDocuments';
import ErrorPlaceholder from '../../../components/UI/Common/ErrorPlaceholder';
import Loading from '../../../components/UI/Common/Loading';
import Empty from '../../../components/UI/Common/Empty';
import BoxUploadDocumentButton from '../../../components/UI/BoxUploadDocumentButton';
import DocumentsTypeSelector from '../../../components/UI/DocumentTypeSelector.tsx/DocumentTypeSelector';
import { CopyAll, Delete, Download, Label, LabelOff, SearchOff } from '@mui/icons-material';
import { useLazyEffect } from '../../../hooks/useLazyEffect';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../theme';
import { isEmpty } from 'lodash';
import DeleteDocumentDialog from '../../../components/Documents/Tables/Documents/DeleteDocumentDialog';
import { ClauseData, EntityType, IDocument } from '../../../@types/Document';
import AddTagModal from '../../../components/Documents/AddTagModal';
import { getTags } from '../../../api/tags';
import { ITagItem } from '../../../@types/Tag';
import TagSelector from '../../../components/UI/TagSelector.tsx';
import { tagsToArray } from '../../../utils/transformTags';
import SearchBox from '../../../components/UI/Navbar/SearchBox';
import { MeliorTranslate } from '../../../components/MeliorTranslate';

const DocumentsPage = () => {
    const [selectedType, setSelectedType] = useState<string>('All');
    const [selectedDocuments, setSelectedDocuments] = useState<IDocument[]>([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [displayedDocuments, setDisplayedDocuments] = useState<IDocument[]>([]);
    const [isAddTagOpen, setIsAddTagOpen] = useState(false);
    const [taggingMode, setTaggingMode] = useState<string>('');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [documentTags, setDocumentTags] = useState<Object>({});
    const [taggingDocIds, setTaggingDocIds] = useState<string[]>([]);

    const [allTags, setAllTags] = useState<Array<ITagItem>>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const { t } = useTranslation();

    const params = {
        page: page + 1,
        size: rowsPerPage,
        sort_by: '_upload_timestamp',
        sort_order: -1,
        name_filter: searchText,
        type_filter: selectedType === 'All' ? '' : selectedType,
        tags_filter: selectedTags,
    };

    const handleUploadSuccess = (res) => {
        toast.success('Successfully uploaded document');
        let newDocs: IDocument[] = [];
        res.entries.forEach((doc) => {
            newDocs.push({
                id: doc.sha1,
                name: doc.name,
                status: doc.status,
                type: doc.type,
                clauses: {} as ClauseData,
                entities: {} as EntityType,
                box_details: doc.box_details,
                tags: {},
            });
        });
        setDisplayedDocuments(newDocs.concat(displayedDocuments));
    };

    const handleUploadError = (error) => {
        if (error.message) {
            toast.error(error.message);
            return;
        }
        toast.error(error.response.data.message);
    };

    const copyLinksToClipboard = () => {
        const links = selectedDocuments.map((document) => {
            return `${window.location.origin}/client/insights/${document.id}`;
        });
        navigator.clipboard.writeText(links.join('\n'));
        toast.success('Document links have been copied to clipboard.');
    };

    const { isLoading, isFetching, error, documents, refetch } = useDocuments(params, 'documents');

    useEffect(() => {
        getAllTags();
    }, []);

    useEffect(() => {
        setDisplayedDocuments(documents);
    }, [documents]);
    const removeDocuments = (docs: IDocument[]) => {
        setDisplayedDocuments(
            displayedDocuments.filter(
                (document) => !docs.map((doc) => doc.id).includes(document.id)
            )
        );
        setSelectedDocuments([]);
    };

    const showLoading = isLoading || isFetching;

    const setNewlySelectedDocuments = (docs) => {
        if (!docs) return;

        setSelectedDocuments(docs);
        setTaggingDocIds(docs.map((doc) => doc.id));
        setDocTags(docs);
    };

    const setDocTags = (docs) => {
        let tagObject = {};
        docs.forEach((doc) => {
            tagObject = { ...tagObject, ...doc.tags };
        });
        setDocumentTags(tagObject);
    };

    useLazyEffect(() => {
        refetch();
    }, [selectedType, searchText, selectedTags]);

    const getAllTags = () => {
        getTags([]).then((res) => {
            setAllTags(tagsToArray(res.tags) as any);
        });
    };

    const updateTable = (tags: ITagItem[], action) => {
        const docs = displayedDocuments;
        docs.forEach((doc) => {
            if (taggingDocIds.includes(doc.id)) {
                tags.forEach((tag) => {
                    if (action == 'add') doc.tags[tag.name] = tag.color;
                    else if (action == 'delete') delete doc.tags[tag.name];
                });
            }
        });
        setDisplayedDocuments(docs);
        setDocTags(docs.filter((doc) => taggingDocIds.includes(doc.id)));
    };

    return (
        <>
            <Flex alignCenter justifyBetween>
                <SearchBox
                    placeholder={t('Search your contracts')}
                    onChange={(searchKey) => setSearchText(searchKey)}
                />
                <BoxUploadDocumentButton
                    onUploadSuccess={handleUploadSuccess}
                    onUploadError={handleUploadError}
                />
            </Flex>
            <Container topOuterSpacing={1.3}>
                <CardContainer>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Flex justifyBetween>
                            <Tabs value={0} style={{ overflow: 'visible' }}>
                                <Tab
                                    label={
                                        <Flex alignCenter>
                                            <span>
                                                <MeliorTranslate valueKey="Document" />
                                            </span>
                                            {!showLoading && (
                                                <Container leftOuterSpacing={1}>
                                                    <Badge
                                                        badgeContent={displayedDocuments.length}
                                                        color="primary"
                                                    />
                                                </Container>
                                            )}
                                        </Flex>
                                    }
                                />
                            </Tabs>
                            <Flex>
                                {!isEmpty(selectedDocuments) && !showLoading && (
                                    <Flex
                                        style={{
                                            backgroundColor: 'whitesmoke',
                                            borderRadius: '10px',
                                            gap: '10px',
                                            marginRight: '15px',
                                            marginBottom: '10px',
                                            paddingRight: '5px',
                                            paddingLeft: '5px',
                                        }}
                                        alignCenter
                                    >
                                        <Chip
                                            label={selectedDocuments.length}
                                            style={{
                                                backgroundColor: '#DCDCDC',
                                                color: Theme.primary,
                                                fontWeight: 'bold',
                                            }}
                                        />
                                        <IconButton
                                            sx={{ '&:hover': { color: Theme.primary } }}
                                            onClick={() => {
                                                setIsAddTagOpen(true);
                                                setTaggingMode('adding');
                                            }}
                                        >
                                            <Tooltip id="button-tag" title="Add tag">
                                                <Label />
                                            </Tooltip>
                                        </IconButton>
                                        {Boolean(Object.keys(documentTags).length) && (
                                            <IconButton
                                                sx={{ '&:hover': { color: Theme.primary } }}
                                                onClick={() => {
                                                    setIsAddTagOpen(true);
                                                    setTaggingMode('deleting');
                                                }}
                                            >
                                                <Tooltip id="button-tag" title="Remove tag">
                                                    <LabelOff />
                                                </Tooltip>
                                            </IconButton>
                                        )}
                                        <IconButton
                                            sx={{ '&:hover': { color: Theme.primary } }}
                                            onClick={() => copyLinksToClipboard()}
                                        >
                                            <Tooltip id="button-copy" title="Copy shareable links">
                                                <CopyAll />
                                            </Tooltip>
                                        </IconButton>
                                        {/* TODO: Implement multi-download when endpoint is available */}
                                        <IconButton
                                            sx={{ '&:hover': { color: Theme.primary } }}
                                            disabled={true}
                                        >
                                            <Tooltip id="button-download" title="Download">
                                                <Download />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton
                                            sx={{ '&:hover': { color: Theme.primary } }}
                                            onClick={() => setShowDeleteDialog(true)}
                                        >
                                            <Tooltip id="button-delete" title="Delete">
                                                <Delete />
                                            </Tooltip>
                                        </IconButton>
                                    </Flex>
                                )}
                                <DocumentsTypeSelector
                                    selectedType={selectedType}
                                    setSelectedType={setSelectedType}
                                    isATableFilter
                                />
                                <TagSelector
                                    allTags={allTags}
                                    selectedTags={selectedTags}
                                    setSelectedTags={setSelectedTags}
                                    showLabel={true}
                                    height="40px"
                                    loadingTags={false}
                                    showBalloons={true}
                                />
                                {Boolean(selectedType !== 'All' || selectedTags.length) && (
                                    <Flex alignCenter style={{ margin: '10px', marginTop: '0px' }}>
                                        <Button
                                            variant="text"
                                            onClick={() => {
                                                setSelectedType('All');
                                                setSelectedTags([]);
                                            }}
                                        >
                                            <SearchOff />
                                        </Button>
                                    </Flex>
                                )}
                            </Flex>
                        </Flex>
                    </Box>
                    <AddTagModal
                        isOpen={isAddTagOpen}
                        setIsOpen={() => setIsAddTagOpen(!isAddTagOpen)}
                        onSaveTagsSuccess={(tags, action) => {
                            setIsAddTagOpen(false);
                            getAllTags();
                            updateTable(tags, action);
                        }}
                        allTags={allTags}
                        documentIds={taggingDocIds}
                        documentTags={documentTags}
                        mode={taggingMode}
                    />
                    <DeleteDocumentDialog
                        open={showDeleteDialog}
                        documents={selectedDocuments}
                        onClose={() => {
                            setShowDeleteDialog(false);
                        }}
                        onDeleteSuccess={() => {
                            setShowDeleteDialog(false);
                            removeDocuments(selectedDocuments);
                        }}
                    />
                    <TabPanel value={0} index={0}>
                        <>
                            {showLoading && <Loading message={t('Loading documents')} />}
                            {error && <ErrorPlaceholder message={t('An error has occurred')} />}
                            {!showLoading && Boolean(displayedDocuments.length) && (
                                <DocumentsTable
                                    documents={displayedDocuments}
                                    refetch={refetch}
                                    removeDocuments={removeDocuments}
                                    setSelectedDocuments={(docs) => {
                                        setNewlySelectedDocuments(docs);
                                    }}
                                    selectedDocuments={selectedDocuments}
                                    setIsAddTagOpen={setIsAddTagOpen}
                                    setTaggingMode={setTaggingMode}
                                    setDocumentTags={setDocumentTags}
                                    setTaggingDocIds={setTaggingDocIds}
                                    setSelectedTags={setSelectedTags}
                                    selectedTags={selectedTags}
                                    setSelectedType={setSelectedType}
                                />
                            )}
                            {!showLoading && !Boolean(displayedDocuments.length) && (
                                <Empty message={t('No documents found')} />
                            )}
                        </>
                    </TabPanel>
                </CardContainer>
            </Container>
        </>
    );
};

export default DocumentsPage;
