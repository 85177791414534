import { ReactNode, useEffect } from 'react';
import { removeUserSessionData, updateUserSessionData } from '../utils/userSession';
import { isEmpty } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import getFronteggTokenData from '../api/frontegg/getFronteggTokenData';
import { useLocation } from 'react-router-dom';
import { removeUserConfig } from '../utils/userConfig';

interface Props {
    children: ReactNode | null;
}

export default function FronteggRouteGuard({ children }: Props) {
    const location = useLocation();
    const { data, isLoading, refetch } = useQuery(['getTokenData'], getFronteggTokenData, {
        refetchOnMount: false,
        refetchOnWindowFocus: true,
    });

    if (isEmpty(data) && !isLoading) {
        removeUserSessionData();
        removeUserConfig();
        window.location.href = '/login';
    } else {
        updateUserSessionData('roles', data?.roles);
    }

    useEffect(() => {
        refetch();
    }, [location]);

    return <>{children}</>;
}
