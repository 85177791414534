import { ThemeProvider, createTheme } from '@mui/material';
import { Theme } from '../../theme';

interface Props {
    children: React.ReactNode;
}

const theme = createTheme({
    palette: {
        primary: {
            main: Theme.primary,
        },
        success: {
            main: Theme.success,
            contrastText: Theme.whiteColor,
        },
        secondary: {
            main: Theme.secondary,
        },
        warning: {
            main: Theme.warning,
            light: '#FFF8E1',
        },
    },
});

export default function ThemeWrapper(props) {
    return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
