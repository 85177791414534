import styled, { css } from 'styled-components';

interface ISideBarStyled {
    isSideBarMaximized: boolean;
}

export default styled<ISideBarStyled>('aside')`
    height: 100vh;
    position: fixed;
    width: 248px;
    min-width: 248px;
    background: #0d1832;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14),
        0px 1px 3px rgba(0, 0, 0, 0.12);
    transition: width 0.5s ease;
    font-family: 'Poppins', sans-serif;

    ${({ isSideBarMaximized }: ISideBarStyled) =>
        css`
            ${isSideBarMaximized ? `width: 248px;` : `width: 80px;`};
            ${isSideBarMaximized ? `min-width: 248px;` : `min-width: 80px;`};
        `}
`;

export const StyledLogoLink = styled('a')`
    img {
        width: 100%;
        height: 44px;
        padding-left: 20px;
        padding-right: 20px;
    }
`;
